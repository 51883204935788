import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const adviseTypeApi = {

	async create(data) {
		return instance.post(`advise-type`, data, await headersAuthToken());
	},

	async update(data) {
		return instance.put(`advise-type`, data, await headersAuthToken());
	},

	async delete(id) {
		return instance.delete(`advise-type?id=${id}`, await headersAuthToken());
	},

	async getList() {
		return instance.get(`advise-type/admin`, await headersAuthToken());
	},

};

