import Detail from 'modules/advise/view';
import React, { memo } from 'react';

type Props = {
  //
};

const DetailAdvise: React.FC<Props> = () => {
  return (
    <>
      <Detail />
    </>
  );
};

export default memo(DetailAdvise);
