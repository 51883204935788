import React, { memo } from 'react';
import TypeBlogContainer from 'modules/blog/type'

type Props = {
  //
};

const TypeBlog: React.FC<Props> = () => {
  return (
    <>
      <TypeBlogContainer />
    </>
  );
};

export default memo(TypeBlog);
