import { Card, Col, Row } from 'antd';
import Title from 'components/Title';
import React, { memo, useEffect } from 'react';
import Create from './components/create';
import Table from './components/table';
import { RootState } from 'store/types';
import { useSelector } from 'react-redux';
import { useHomeIntro } from 'hooks/useHomeIntro';

type Props = {
  //
};

const HomeContainer: React.FC<Props> = () => {

  const { getDataList } = useHomeIntro()
  const { skip } = useSelector((state: RootState) => state?.homeIntro)

  useEffect(() => {
    getDataList(skip)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <Title level={4}>Phần giới thiệu</Title>
        </Col>

        <Col sm={16} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} className='overflow-auto'>
            <Table />
          </Card>
        </Col>
        <Col sm={8} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} className='overflow-auto'>
            <Create />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default memo(HomeContainer);
