import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const projectTypeApi = {

	async create(data) {
		return instance.post(`project-type`, data, await headersAuthToken());
	},

	async update(data) {
		return instance.put(`project-type`, data, await headersAuthToken());
	},

	async delete(id) {
		return instance.delete(`project-type?id=${id}`, await headersAuthToken());
	},

	async getList() {
		return instance.get(`project-type/admin`, await headersAuthToken());
	},

};

