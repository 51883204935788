import { aboutTeamApi } from "configs/api/aboutTeam"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setListAboutTeam } from "store/actions/aboutTeamAction"
import { removeNullUndefined } from "utils"

export function useAboutTeam() {

  const disPatch = useDispatch()

  const createAboutTeam = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = aboutTeamApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const editaboutTeam = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = aboutTeamApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteAboutTeam = async (id) => {

    try {
      const promise = aboutTeamApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async (page) => {

    try {
      const promise = aboutTeamApi.getList(page)
      return await promise.then(async (res) => {
        disPatch(setListAboutTeam(res.data))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    createAboutTeam,
    editaboutTeam,
    getDataList,
    deleteAboutTeam,
  }
}