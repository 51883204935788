import Create from 'modules/project/create';
import React, { memo } from 'react';

type Props = {
  //
};

const CreateProject: React.FC<Props> = () => {
  return (
    <>
      <Create />
    </>
  );
};

export default memo(CreateProject);
