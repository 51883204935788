import React, { memo } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Space, Switch, Table, Tooltip } from 'antd';
import { RootState } from 'store/types';
import { useDispatch, useSelector } from 'react-redux';
import { setBlogTypeEdit, setIsEditBlogType } from 'store/actions/blogAction';
import { useBlogType } from 'hooks/useBlogType';

type Props = {
  //
};

const ListType: React.FC<Props> = () => {

  const { blogTypeList } = useSelector((state: RootState) => state.blog)
  const { getDataList, editBlog } = useBlogType()
  const dispatch = useDispatch()

  const onUpdateStatusBlogType = async (id, active) => {
    await editBlog({
      id: id,
      active: !active
    }).then((res) => {
      getDataList()
    })
  }

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row, index) => {
        return <p>{row.name}</p>;
      },
    },
    {
      title: 'Slug',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row, index) => {
        return <p>{row.slug}</p>;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row) => {
        return (
          <div className="flex-align-items">
            <Switch
              defaultChecked={row?.active}
              onChange={(checked, event) => {
                onUpdateStatusBlogType(row._id, row.active);
              }}
            />
            <span style={{ marginLeft: 10 }}>{row?.active ? 'Active' : 'Inactive'}</span>
          </div>
        );
      },
    },
    {
      title: 'Hành động',
      dataIndex: 'id',
      width: '5%',
      render: (cell, row, index) => {
        return (
          <Space>
            <Tooltip title={'Sửa'}>
              <div
                onClick={() => {
                  dispatch(setIsEditBlogType(true))
                  dispatch(setBlogTypeEdit(row))
                }}
              >
                <Button icon={<EditOutlined />} />
              </div>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        // @ts-ignore
        dataSource={blogTypeList?.data || []}
        columns={columns}
        style={{ margin: 0 }}
        className="table_list"
        pagination={false}
      />
    </>
  );
};

export default memo(ListType);
