import { PictureOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import Upload, { DraggerProps } from 'antd/lib/upload';
import BraftEditor, { ControlType, EditorState, ExtendControlType } from 'braft-editor';
import 'braft-extensions/dist/table.css';
import React from 'react';
import { ContentUtils } from 'braft-utils';
import { useUpload } from 'hooks/useUpload';

const { Dragger } = Upload;
export const EditorColor: string[] = [
  '#5BC5F2',
  '#E4F7FF',
  '#165BAA',
  '#E7E8FB',
  '#FB4390',
  '#FFE9EF',
  '#D63120',
  '#FFF0F0',
  '#141ED2',
  '#E7E8FB',
  '#000000',
  '#333333',
  '#666666',
  '#999999',
  '#cccccc',
  '#ffffff',
  '#61a951',
  '#16a085',
  '#07a9fe',
  '#003ba5',
  '#8e44ad',
  '#f32784',
  '#c0392b',
  '#d35400',
  '#f39c12',
  '#fdda00',
  '#7f8c8d',
  '#2c3e50',
];

type Props = {
  controls?: ControlType[];
  className?: string;
  placeholder?: string;
  value: EditorState;
  handleChangeText: (text: EditorState) => void;
};

const RickTextEditorForm: React.FC<Props> = React.memo(
  ({ controls, className, placeholder, value, handleChangeText }) => {
    const [editorState, setEditorState] = React.useState<EditorState>(value);

    const { upload } = useUpload()

    const handleEditorChange = (editorStateVal: EditorState) => {
      setEditorState(editorStateVal);
      handleChangeText?.(editorStateVal);
    };

    const draggerProps: DraggerProps = {
      name: 'file',
      multiple: false,
      className: 'upload-list-inline',
      customRequest: async ({ onError, onProgress, onSuccess, file }: any) => {
        const formData = new FormData();
        formData.append('image', file);
        await upload(formData).then(async (res) => {
          setEditorState(
            ContentUtils.insertMedias(editorState, [
              {
                type: 'IMAGE',
                url: res,
                name: '',
                meta: {
                  class: 'mbal-lazyload',
                  name: res,
                  alt: res,
                },
              },
            ]),
          );
        })
      },
    };

    const extendControls: ExtendControlType[] = [
      {
        key: 'antd-uploader',
        type: 'component',
        component: (
          <Tooltip title="Media" placement="bottom">
            <Dragger {...draggerProps}>
              <PictureOutlined style={{ fontSize: 16, marginTop: 14 }} />
            </Dragger>
          </Tooltip>
        ),
      },
    ];

    return (
      <BraftEditor
        placeholder={placeholder || 'Nhập gì đó...'}
        language="en"
        className={`sdecorBraftEditor ${className}`}
        controls={controls}
        media={{
          accepts: {
            audio: false,
            video: false,
          },
          externals: {
            image: true,
            video: false,
            audio: false,
            embed: true,
          },
        }}
        colors={EditorColor}
        value={editorState}
        onChange={handleEditorChange}
        extendControls={extendControls}
      />
    );
  },
);
export default RickTextEditorForm;

RickTextEditorForm.defaultProps = {
  controls: [
    'redo',
    'undo',
    'separator',
    'emoji',
    'font-family',
    'font-size',
    'fullscreen',
    'headings',
    'letter-spacing',
    'line-height',
    'separator',
    'text-color',
    'bold',
    'italic',
    'strike-through',
    'separator',
    'superscript',
    'subscript',
    'remove-styles',
    'separator',
    'text-indent',
    'underline',
    'text-align',
    'separator',
    'list-ol',
    'list-ul',
    'table',
    'blockquote',
    'code',
    'separator',
    'link',
    'hr',
    'clear',
    'separator',
    'media',
    'separator',
  ],
};
