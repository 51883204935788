export const SET_LIST_BLOG_TYPE = 'SET_LIST_BLOG_TYPE';
export const SET_IS_EDIT_BLOG_TYPE = 'SET_IS_EDIT_BLOG_TYPE';
export const SET_BLOG_TYPE_EDIT = 'SET_BLOG_TYPE_EDIT';

export interface BlogState {
  blogTypeList: any;
  isEditBlogType: boolean;
  blogTypeEdit: any;
}

interface setListBlogTypeAction {
  type: typeof SET_LIST_BLOG_TYPE;
  payload: any;
}

interface setIsEditBlogTypeAction {
  type: typeof SET_IS_EDIT_BLOG_TYPE;
  payload: any;
}

interface setBlogTypeEditAction {
  type: typeof SET_BLOG_TYPE_EDIT;
  payload: any;
}

export type BlogAction = setListBlogTypeAction | setIsEditBlogTypeAction | setBlogTypeEditAction;
