import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import HomeOperationalResultsContainer from 'modules/home/operational-results'

type Props = {
  //
};

const Home: React.FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Thành Quả Hoạt Động</title>
      </Helmet>
      <HomeOperationalResultsContainer />
    </>
  );
};

export default memo(Home);
