export const SET_LIST_ADVISE_TYPE = 'SET_LIST_ADVISE_TYPE';
export const SET_IS_EDIT_ADVISE_TYPE = 'SET_IS_EDIT_ADVISE_TYPE';
export const SET_ADVISE_TYPE_EDIT = 'SET_ADVISE_TYPE_EDIT';

export interface AdviseState {
  adviseTypeList: any;
  isEditAdviseType: boolean;
  adviseTypeEdit: any;
}

interface setListAdviseTypeAction {
  type: typeof SET_LIST_ADVISE_TYPE;
  payload: any;
}

interface setIsEditAdviseTypeAction {
  type: typeof SET_IS_EDIT_ADVISE_TYPE;
  payload: any;
}

interface setAdviseTypeEditAction {
  type: typeof SET_ADVISE_TYPE_EDIT;
  payload: any;
}

export type AdviseAction = setListAdviseTypeAction | setIsEditAdviseTypeAction | setAdviseTypeEditAction;
