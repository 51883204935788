import React, { memo, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { setHomeIntroFactoryEdit, setIsEdit } from 'store/actions/homeIntroFactoryAction';
import { useUpload } from 'hooks/useUpload';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { useHomeIntroFactory } from 'hooks/useHomeIntroFactory';
import UploadFileSingle from 'components/UploadFile/Single';
import ImageAnt from 'components/ImageAnt';
import { UploadFile } from 'antd/lib/upload';

type Props = {
  // 
};

const CreateHomeIntroFactory: React.FC<Props> = () => {

  const { upload } = useUpload()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const dispatch = useDispatch()
  const { isEdit, homeIntroFactoryEdit, skip } = useSelector((state: RootState) => state.homeIntroFactory)
  const { createHomeIntroFactory, edithomeIntroFactory, getDataList } = useHomeIntroFactory()
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm();

  const onFinish = async () => {
    const fields = form.getFieldsValue();
    setLoading(true)

    try {
      if (isEdit) {
        if (fileList.length >= 1) {
          const formData = new FormData();
          formData.append('image', fileList[0].originFileObj);
          await upload(formData).then(async (res) => {
            await edithomeIntroFactory(
              {
                id: homeIntroFactoryEdit._id,
                ...fields,
                background: res
              }
            )
              .then((res) => {
                getDataList(skip)
                dispatch(setHomeIntroFactoryEdit(null))
                dispatch(setIsEdit(false))
                setLoading(false)
              })
          })
        } else {
          await edithomeIntroFactory(
            {
              id: homeIntroFactoryEdit._id,
              ...fields
            }
          )
            .then((res) => {
              getDataList(skip)
              dispatch(setHomeIntroFactoryEdit(null))
              dispatch(setIsEdit(false))
              setLoading(false)
            })
        }
      } else {
        if (fileList.length >= 1) {
          const formData = new FormData();
          formData.append('image', fileList[0].originFileObj);
          await upload(formData).then(async (res) => {
            await createHomeIntroFactory({ ...fields, background: res })
              .then((res) => {
                getDataList(skip)
                setLoading(false)
              })
          })
        }
      }
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    form.resetFields();
    if (isEdit) {
      form.setFieldsValue({
        background: homeIntroFactoryEdit.background,
        videoUrl: homeIntroFactoryEdit.videoUrl,
      });
    }
    // eslint-disable-next-line
  }, [isEdit, homeIntroFactoryEdit])

  return (
    <>
      <Form
        name="normal_reviews"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item label="Hình ảnh">
          {
            isEdit && (
              <ImageAnt
                id={homeIntroFactoryEdit.background}
                preview={true}
                options={{
                  width: 100,
                }}
              />
            )
          }
          <UploadFileSingle aspect={1024 / 352} fileList={fileList} setFileList={setFileList} />
        </Form.Item>
        <Form.Item name="videoUrl" label="Link youtube video" rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}>
          <Input className='w-full' placeholder="Link youtube video" />
        </Form.Item>
        <Form.Item>
          {isEdit ? (
            <div className='flex items-center'>
              <div
                className='mr-2 py-1 px-3 h-full bg-red-600 cursor-pointer text-white rounded'
                onClick={() => {
                  if (!loading) {
                    dispatch(setIsEdit(false))
                  }
                }}
              >
                Cancel
              </div>
              <Button type="primary" htmlType="submit" loading={loading}>
                Update
              </Button>
            </div>
          ) : (
            <Button type="primary" htmlType="submit" loading={loading}>
              Thêm
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default memo(CreateHomeIntroFactory);
