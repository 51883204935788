import { Button, Card, Col, Divider, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Title from 'components/Title';
import React, { memo, useEffect } from 'react';
import { useQuote } from 'hooks/useQuote';
import { useLocation } from 'react-router-dom';

type Props = {
  setIsView: any,
  itemView: any,
};

const View: React.FC<Props> = ({ setIsView, itemView }) => {
  const { seenQuote, getDataList } = useQuote()

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("page");

  useEffect(() => {
    seenQuote(itemView._id).then((res) => {
      getDataList(param ? param : 1)
    })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div
        className='mb-3'
        onClick={() => {
          setIsView(false)
        }}
      >
        <Button icon={<ArrowLeftOutlined />} />
      </div>
      <Row gutter={24}>
        <Col sm={12} xs={24} style={{ marginBottom: 20 }}>
          <Card title='Thông tin' bordered={true} className='overflow-auto h-full'>
            <Row gutter={24} className="kar-row-item">
              <Col span={8}>
                <Title level={5}>Họ tên:</Title>
              </Col>
              <Col span={16}>
                <p>{itemView.name}</p>
              </Col>
              <Divider />
              <Col span={8}>
                <Title level={5}>Số điện thoại:</Title>
              </Col>
              <Col span={16}>
                <p>{itemView.phone}</p>
              </Col>
              <Divider />
              <Col span={8}>
                <Title level={5}>Email:</Title>
              </Col>
              <Col span={16}>
                <p>{itemView.email}</p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col sm={12} xs={24} style={{ marginBottom: 20 }}>
          <Card title='Nội dung' bordered={true} className='overflow-auto h-full'>
            <div dangerouslySetInnerHTML={{ __html: itemView.content }}></div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default memo(View);
