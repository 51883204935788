import { Card, Col, Row } from 'antd';
import Title from 'components/Title';
import React, { memo, useEffect, useState } from 'react';
import List from './components/list';
import { useQuote } from 'hooks/useQuote';
import View from './components/view';
import { useLocation } from 'react-router-dom';

type Props = {
  //
};

const QuoteContainer: React.FC<Props> = () => {

  const { getDataList } = useQuote()
  const [itemView, setItemView] = useState(null)
  const [isView, setIsView] = useState(false)

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("page");

  useEffect(() => {
    getDataList(param ? param : 1)
    // eslint-disable-next-line
  }, [param])

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <Title level={4}>Báo giá</Title>
        </Col>

        <Col sm={24} xs={24} style={{ marginBottom: 20 }}>

          {
            isView
              ? <View setIsView={setIsView} itemView={itemView} />
              : (<Card bordered={true} className='overflow-auto'>
                <List setItemView={setItemView} setIsView={setIsView} />
              </Card>)
          }
        </Col>
      </Row>
    </>
  );
};

export default memo(QuoteContainer);
