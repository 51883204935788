import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const adviseApi = {

	async create(data) {
		return instance.post(`advise`, data, await headersAuthToken());
	},

	async getDetail(id) {
		return instance.get(`advise/admin/detail/${id}`, await headersAuthToken());
	},

	async getList(page, type?: string) {
		return instance.get(`advise/admin/list?page=${page}${type ? `&type=${type}` : ''}`, await headersAuthToken());
	},

	async update(data) {
		return instance.put(`advise`, data, await headersAuthToken());
	},

	async delete(id) {
		return instance.delete(`advise/${id}`, await headersAuthToken());
	},

};

