import { uploadApi } from "configs/api/upload"

export function useUpload() {

  const upload = async (file) => {

    try {
      const promise = uploadApi.upload(file)

      return await promise.then(async (res) => {
        return res.data
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getFile = async (id) => {

    try {
      return await uploadApi.getFile(id).then(async (_res: any) => {
        const imageUrl = URL.createObjectURL(_res.data);
        return imageUrl
      })
    } catch (err) {
      throw err
    }

  }

  return {
    upload,
    getFile
  }
}