import React, { memo, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { setHomeIntroEdit, setIsEdit } from 'store/actions/homeIntroAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { useHomeIntro } from 'hooks/useHomeIntro';

type Props = {
  // 
};

const CreateHomeIntro: React.FC<Props> = () => {

  const dispatch = useDispatch()
  const { isEdit, homeIntroEdit, skip } = useSelector((state: RootState) => state.homeIntro)
  const { createHomeIntro, edithomeIntro, getDataList } = useHomeIntro()
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm();

  const onFinish = async () => {
    const fields = form.getFieldsValue();
    setLoading(true)

    try {
      if (isEdit) {
        await edithomeIntro(
          {
            id: homeIntroEdit._id,
            ...fields,
          }
        )
          .then((res) => {
            getDataList(skip)
            dispatch(setHomeIntroEdit(null))
            dispatch(setIsEdit(false))
            setLoading(false)
          })
      } else {
        await createHomeIntro({ ...fields })
          .then((res) => {
            getDataList(skip)
            setLoading(false)
          })
      }
    } catch (err) {
      setLoading(false)
    }
  }



  useEffect(() => {
    form.resetFields();
    if (isEdit) {
      form.setFieldsValue({
        title: homeIntroEdit.title,
        content: homeIntroEdit.content
      });
    }
    // eslint-disable-next-line
  }, [isEdit, homeIntroEdit])

  return (
    <>
      <Form
        name="normal_reviews"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item name="title" label="Tiêu đề" rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}>
          <Input placeholder="Tiêu đề" />
        </Form.Item>
        <Form.Item name="content" label="Nội dung" rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}>
          <Input placeholder="Nội dung" />
        </Form.Item>
        <Form.Item>
          {isEdit ? (
            <div className='flex items-center'>
              <div
                className='mr-2 py-1 px-3 h-full bg-red-600 cursor-pointer text-white rounded'
                onClick={() => {
                  if (!loading) {
                    dispatch(setIsEdit(false))
                  }
                }}
              >
                Cancel
              </div>
              <Button type="primary" htmlType="submit" loading={loading}>
                Update
              </Button>
            </div>
          ) : (
            <Button type="primary" htmlType="submit" loading={loading}>
              Thêm
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default memo(CreateHomeIntro);
