import { AdviseAction, AdviseState, SET_IS_EDIT_ADVISE_TYPE, SET_LIST_ADVISE_TYPE, SET_ADVISE_TYPE_EDIT } from '../types/adviseTypes';

const initialState: AdviseState = {
  adviseTypeList: null,
  isEditAdviseType: false,
  adviseTypeEdit: null,
};

const AdviseReducer = (state = initialState, action: AdviseAction): AdviseState => {
  switch (action.type) {
    case SET_LIST_ADVISE_TYPE:
      return {
        ...state,
        adviseTypeList: action?.payload,
      };
    case SET_IS_EDIT_ADVISE_TYPE:
      return {
        ...state,
        isEditAdviseType: action?.payload,
      };
    case SET_ADVISE_TYPE_EDIT:
      return {
        ...state,
        adviseTypeEdit: action?.payload,
      };
    default:
      return state;
  }
};

export default AdviseReducer;
