import { bannerApi } from "configs/api/banner"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setListBanner } from "store/actions/bannerAction"
import { removeNullUndefined } from "utils"

export function useBanner() {

  const disPatch = useDispatch()

  const createBanner = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = bannerApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const editbanner = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = bannerApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteBanner = async (id) => {

    try {
      const promise = bannerApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async (page) => {

    try {
      const promise = bannerApi.getList(page)
      return await promise.then(async (res) => {
        disPatch(setListBanner(res.data))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    createBanner,
    editbanner,
    getDataList,
    deleteBanner,
  }
}