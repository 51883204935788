// eslint-disable-next-line
import React, { memo, useEffect } from "react";

const handleClickOutside = (event, ref, set) => {
    if (ref.current && !ref.current.contains(event.target)) {
        set(false);
    }
}

export function useRefCustom(ref, actions) {
    useEffect(() => {
        document.addEventListener("click", (e) => handleClickOutside(e, ref, actions));
        return () => {
            document.removeEventListener("click", (e) => handleClickOutside(e, ref, actions));
        };
        // eslint-disable-next-line
    }, [ref]);
}