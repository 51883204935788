import { SET_HOME_OPERATIONAL_RESULTS_EDIT, SET_IS_EDIT_HOME_OPERATIONAL_RESULTS, SET_LIST_HOME_OPERATIONAL_RESULTS, SET_SKIP_HOME_OPERATIONAL_RESULTS } from '../types/homeOperationalResultsTypes';

export const setListHomeOperationalResults = (homeOperationalResultss: any) => {
  return {
    type: SET_LIST_HOME_OPERATIONAL_RESULTS,
    payload: homeOperationalResultss,
  };
};


export const setIsEdit = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_HOME_OPERATIONAL_RESULTS,
    payload: isEdit,
  };
};


export const setHomeOperationalResultsEdit = (homeOperationalResults: any) => {
  return {
    type: SET_HOME_OPERATIONAL_RESULTS_EDIT,
    payload: homeOperationalResults,
  };
};

export const setSkip = (skip: number) => {
  return {
    type: SET_SKIP_HOME_OPERATIONAL_RESULTS,
    payload: skip,
  };
};