import { SET_ABOUT_VISION_EDIT, SET_IS_EDIT_ABOUT_VISION, SET_LIST_ABOUT_VISION, SET_SKIP_ABOUT_VISION } from '../types/aboutVisionTypes';

export const setListAboutVision = (aboutVisions: any) => {
  return {
    type: SET_LIST_ABOUT_VISION,
    payload: aboutVisions,
  };
};


export const setIsEdit = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_ABOUT_VISION,
    payload: isEdit,
  };
};


export const setAboutVisionEdit = (aboutVision: any) => {
  return {
    type: SET_ABOUT_VISION_EDIT,
    payload: aboutVision,
  };
};

export const setSkip = (skip: number) => {
  return {
    type: SET_SKIP_ABOUT_VISION,
    payload: skip,
  };
};