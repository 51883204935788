import React, { memo } from "react";
import styles from './styles.module.css'
import { Link } from "react-router-dom";
import { AppRoute } from "configs/router/config.router";

const NotFound = () => {
    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.text}>404</div>
                    <div className={styles.description}>Oops, page not found.</div>
                    <Link className={styles.btnBackHome} to={AppRoute.dashboard}>Back Home</Link>
                </div>
            </div>
        </>
    )
}

export default memo(NotFound)