import React, { memo } from 'react';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Switch, Table, Tooltip } from 'antd';
import { useAboutTeam } from 'hooks/useAboutTeam';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { setAboutTeamEdit, setIsEdit } from 'store/actions/aboutTeamAction';
import ImageAnt from 'components/ImageAnt';

type Props = {
  // 
};

const { confirm } = Modal;

const TableComponent: React.FC<Props> = () => {

  const dispatch = useDispatch()
  const { aboutTeamList, skip } = useSelector((state: RootState) => state.aboutTeam)
  const { getDataList, deleteAboutTeam, editaboutTeam } = useAboutTeam()

  const onChangePagination = (_skip: number) => { }

  const onUpdateStatusAboutTeam = async (id, active) => {
    await editaboutTeam({
      id: id,
      active: !active
    }).then((res) => {
      getDataList(skip)
    })
  }

  const showConfirmDelete = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <Button>Are you sure?</Button>,
      onOk() {
        deleteAboutTeam(id).then((res) => {
          getDataList(skip)
        });
      },
      onCancel() {
      },
    });
  };

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row, index) => {
        return <p>{row.name}</p>;
      },
    },
    {
      title: 'Vị trí',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row, index) => {
        return <p>{row.position}</p>;
      },
    },
    {
      title: 'Mô tả ngắn',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row, index) => {
        return <p>{row.description?.length > 30 ? `${row.description?.substring(0, 27)}...` : row.description}</p>;
      },
    },
    {
      title: 'Avatar',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row, index) => {
        return <ImageAnt
          id={row.avatar}
          preview={true}
          options={{
            width: 100,
          }}
        />
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row) => {
        return (
          <div className="flex-align-items">
            <Switch
              checked={row?.active}
              onChange={(checked, event) => {
                onUpdateStatusAboutTeam(row._id, row.active);
              }}
            />
            <span style={{ marginLeft: 10 }}>{row?.active ? 'Active' : 'Inactive'}</span>
          </div>
        );
      },
    },
    {
      title: 'Hành động',
      dataIndex: 'id',
      width: '5%',
      render: (cell, row, index) => {
        return (
          <Space>
            <Tooltip title={'Sửa'}>
              <div
                onClick={() => {
                  dispatch(setIsEdit(true))
                  dispatch(setAboutTeamEdit(row))
                }}
              >
                <Button icon={<EditOutlined />} />
              </div>
            </Tooltip>
            <Tooltip title={'Delete'}>
              <Button
                onClick={() => {
                  showConfirmDelete(row._id)
                }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        // @ts-ignore
        dataSource={aboutTeamList?.data || []}
        columns={columns}
        style={{ margin: 0 }}
        className="table_list"
        pagination={{
          position: ['bottomCenter'],
          current: parseFloat((1).toString()),
          total: 1,
          pageSize: 12,
          onChange: (skip: number) => onChangePagination(skip),
          showSizeChanger: false,
        }}
      />
    </>
  );
};

export default memo(TableComponent);
