import { AboutVisionAction, AboutVisionState, SET_ABOUT_VISION_EDIT, SET_IS_EDIT_ABOUT_VISION, SET_LIST_ABOUT_VISION, SET_SKIP_ABOUT_VISION } from '../types/aboutVisionTypes';

const initialState: AboutVisionState = {
  aboutVisionList: null,
  isEdit: false,
  aboutVisionEdit: null,
  skip: 1
};

const AboutVisionReducer = (state = initialState, action: AboutVisionAction): AboutVisionState => {
  switch (action.type) {
    case SET_LIST_ABOUT_VISION:
      return {
        ...state,
        aboutVisionList: action?.payload,
      };
    case SET_IS_EDIT_ABOUT_VISION:
      return {
        ...state,
        isEdit: action?.payload,
      };
    case SET_ABOUT_VISION_EDIT:
      return {
        ...state,
        aboutVisionEdit: action?.payload,
      };
    case SET_SKIP_ABOUT_VISION:
      return {
        ...state,
        skip: action?.payload,
      };
    default:
      return state;
  }
};

export default AboutVisionReducer;
