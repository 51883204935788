import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routers from 'routers';

export default function Home() {

    return (
        <>
            <Routers />
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                icon={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{
                    top: '61px'
                }}
            />
        </>
    )
}