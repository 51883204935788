import React, { memo } from 'react';
import QuoteContainer from 'modules/quote'

type Props = {
  //
};

const Quote: React.FC<Props> = () => {
  return (
    <>
      <QuoteContainer />
    </>
  );
};

export default memo(Quote);
