import { Image } from 'antd';
import React, { memo } from 'react';

type Props = {
  id: string
  preview?: boolean
  options?: any
};

const ImageAnt: React.FC<Props> = ({ id, options, preview = false }) => {

  return (
    <>
      {
        <Image
          {...options}
          src={id}
          placeholder={
            <Image
              src={id}
              preview={false}
              width={1000}
            />
          }
        />
      }
    </>
  );
};

export default memo(ImageAnt);
