import { AboutTeamAction, AboutTeamState, SET_ABOUT_TEAM_EDIT, SET_IS_EDIT_ABOUT_TEAM, SET_LIST_ABOUT_TEAM, SET_SKIP_ABOUT_TEAM } from '../types/aboutTeamTypes';

const initialState: AboutTeamState = {
  aboutTeamList: null,
  isEdit: false,
  aboutTeamEdit: null,
  skip: 1
};

const AboutTeamReducer = (state = initialState, action: AboutTeamAction): AboutTeamState => {
  switch (action.type) {
    case SET_LIST_ABOUT_TEAM:
      return {
        ...state,
        aboutTeamList: action?.payload,
      };
    case SET_IS_EDIT_ABOUT_TEAM:
      return {
        ...state,
        isEdit: action?.payload,
      };
    case SET_ABOUT_TEAM_EDIT:
      return {
        ...state,
        aboutTeamEdit: action?.payload,
      };
    case SET_SKIP_ABOUT_TEAM:
      return {
        ...state,
        skip: action?.payload,
      };
    default:
      return state;
  }
};

export default AboutTeamReducer;
