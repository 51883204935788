import { SET_ABOUT_SERVICE_EDIT, SET_IS_EDIT_ABOUT_SERVICE, SET_LIST_ABOUT_SERVICE, SET_SKIP_ABOUT_SERVICE } from '../types/aboutServiceTypes';

export const setListAboutService = (homeIntros: any) => {
  return {
    type: SET_LIST_ABOUT_SERVICE,
    payload: homeIntros,
  };
};


export const setIsEdit = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_ABOUT_SERVICE,
    payload: isEdit,
  };
};


export const setAboutServiceEdit = (homeIntro: any) => {
  return {
    type: SET_ABOUT_SERVICE_EDIT,
    payload: homeIntro,
  };
};

export const setSkip = (skip: number) => {
  return {
    type: SET_SKIP_ABOUT_SERVICE,
    payload: skip,
  };
};