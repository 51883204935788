import { AppRoute } from "configs/router/config.router";
import React, { memo, useEffect, useState } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

const RouteLogin = (props: RouteProps) => {
    const [loged, setLoged] = useState(false)

    const _validateToken = async () => {
        setLoged(false)
    }

    useEffect(() => {
        _validateToken()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {
                !loged
                    ? <Route {...props} />
                    : <Redirect to={AppRoute.setting} />
            }
        </>
    )

}

export default memo(RouteLogin)