import { keyLocalStorage } from "../constants";
import { getLocalStorageServices } from "./offlineServices";

export const headersAuthToken = () => {
    const token = getLocalStorageServices(keyLocalStorage.login);
    return {
        headers: { Authorization: `${token}` }
    }
}

export const uploadFileheadersAuthToken = () => {
    const token = getLocalStorageServices(keyLocalStorage.login);
    return {
        headers: {
            Authorization: `${token}`,
            'Content-Type': 'multipart/form-data'
        }
    }
}