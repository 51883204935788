import React, { memo, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { setProjectTypeEdit, setIsEditProjectType } from 'store/actions/projectAction';
import { useProjectType } from 'hooks/useProjectType';

type Props = {
  //
};

const Create: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const { isEditProjectType, projectTypeEdit } = useSelector((state: RootState) => state.project)
  const [loading, setLoading] = useState(false)
  const { createProject, editProject, getDataList } = useProjectType()

  const onFinish = async () => {
    const fields = form.getFieldsValue();
    setLoading(true)

    try {
      if (isEditProjectType) {
        await editProject({
          id: projectTypeEdit._id,
          ...fields,
        }).then((res) => {
          getDataList()
          dispatch(setProjectTypeEdit(null))
          dispatch(setIsEditProjectType(false))
          setLoading(false)
        })
      } else {
        await createProject({ ...fields })
          .then((res) => {
            getDataList()
            setLoading(false)
          })
      }
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    form.resetFields();
    if (isEditProjectType) {
      form.setFieldsValue({
        name: projectTypeEdit.name,
      });
    }
    // eslint-disable-next-line
  }, [isEditProjectType, projectTypeEdit])

  return (
    <>
      <Form
        name="normal_reviews"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item name="name" label="Tên loại" rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}>
          <Input placeholder="Tên loại" />
        </Form.Item>

        <Form.Item>
          {isEditProjectType ? (
            <div className='flex items-center'>
              <div
                className='mr-2 py-1 px-3 h-full bg-red-600 cursor-pointer text-white rounded'
                onClick={() => {
                  if (!loading) {
                    dispatch(setProjectTypeEdit(false))
                  }
                }}
              >
                Cancel
              </div>
              <Button type="primary" htmlType="submit" loading={loading}>
                Update
              </Button>
            </div>
          ) : (
            <Button type="primary" htmlType="submit" loading={loading}>
              Thêm
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default memo(Create);
