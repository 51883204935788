import { homeIntroFactoryApi } from "configs/api/homeIntroFactory"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setListHomeIntroFactory } from "store/actions/homeIntroFactoryAction"
import { removeNullUndefined } from "utils"

export function useHomeIntroFactory() {

  const disPatch = useDispatch()

  const createHomeIntroFactory = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = homeIntroFactoryApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const edithomeIntroFactory = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = homeIntroFactoryApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteHomeIntroFactory = async (id) => {

    try {
      const promise = homeIntroFactoryApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async (page) => {

    try {
      const promise = homeIntroFactoryApi.getList(page)
      return await promise.then(async (res) => {
        disPatch(setListHomeIntroFactory(res.data.data[0]))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    createHomeIntroFactory,
    edithomeIntroFactory,
    getDataList,
    deleteHomeIntroFactory,
  }
}