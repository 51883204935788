import { HomeTrendDesignAction, HomeTrendDesignState, SET_HOME_TREND_DESIGN_EDIT, SET_IS_EDIT_HOME_TREND_DESIGN, SET_LIST_HOME_TREND_DESIGN, SET_SKIP_HOME_TREND_DESIGN } from '../types/homeTrendDesignTypes';

const initialState: HomeTrendDesignState = {
  homeTrendDesignList: null,
  isEdit: false,
  homeTrendDesignEdit: null,
  skip: 1
};

const HomeTrendDesignReducer = (state = initialState, action: HomeTrendDesignAction): HomeTrendDesignState => {
  switch (action.type) {
    case SET_LIST_HOME_TREND_DESIGN:
      return {
        ...state,
        homeTrendDesignList: action?.payload,
      };
    case SET_IS_EDIT_HOME_TREND_DESIGN:
      return {
        ...state,
        isEdit: action?.payload,
      };
    case SET_HOME_TREND_DESIGN_EDIT:
      return {
        ...state,
        homeTrendDesignEdit: action?.payload,
      };
    case SET_SKIP_HOME_TREND_DESIGN:
      return {
        ...state,
        skip: action?.payload,
      };
    default:
      return state;
  }
};

export default HomeTrendDesignReducer;
