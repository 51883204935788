import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import globalReducer from './reducers/globalReducer';
import bannerReducer from './reducers/bannerReducer';
import projectReducer from './reducers/projectReducer';
import blogReducer from './reducers/blogReducer';
import adviseReducer from './reducers/adviseReducer';
import quoteReducer from './reducers/quoteReducer';
import HomeIntroReducer from './reducers/homeIntroReducer';
import HomeOperationalResultsReducer from './reducers/homeOperationalResultsReducer';
import HomeDesignProcessReducer from './reducers/homeDesignProcessReducer';
import HomeIntroFactoryReducer from './reducers/homeIntroFactoryReducer';
import HomeTrendDesignReducer from './reducers/homeTrendDesignReducer';
import AboutServiceReducer from './reducers/aboutServiceReducer';
import AboutValueReducer from './reducers/aboutValueReducer';
import AboutVisionReducer from './reducers/aboutVisionReducer';
import AboutTeamReducer from './reducers/aboutTeamReducer';

const rootReducer = combineReducers({
  global: globalReducer,
  banner: bannerReducer,
  project: projectReducer,
  blog: blogReducer,
  advise: adviseReducer,
  quote: quoteReducer,
  homeIntro: HomeIntroReducer,
  homeDesignProcess: HomeDesignProcessReducer,
  homeIntroFactory: HomeIntroFactoryReducer,
  homeOperationalResults: HomeOperationalResultsReducer,
  homeTrendDesign: HomeTrendDesignReducer,
  aboutService: AboutServiceReducer,
  aboutValue: AboutValueReducer,
  aboutVision: AboutVisionReducer,
  aboutTeam: AboutTeamReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
