import { adviseTypeApi } from "configs/api/advise-type"
import { useDispatch } from "react-redux"
import { setListAdviseType } from "store/actions/adviseAction"
import { toast } from "react-toastify"

export function useAdviseType() {

  const disPatch = useDispatch()

  const createAdviseType = async (values) => {

    try {
      const promise = adviseTypeApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const editAdviseType = async (values) => {

    try {
      const promise = adviseTypeApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteAdviseType = async (id) => {

    try {
      const promise = adviseTypeApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async () => {

    try {
      const promise = adviseTypeApi.getList()
      return await promise.then(async (res) => {
        disPatch(setListAdviseType(res.data))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    getDataList,
    deleteAdviseType,
    createAdviseType,
    editAdviseType
  }
}