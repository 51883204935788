export const SET_LIST_HOME_INTRO = 'SET_LIST_HOME_INTRO';
export const SET_IS_EDIT_HOME_INTRO = 'SET_IS_EDIT_HOME_INTRO';
export const SET_HOME_INTRO_EDIT = 'SET_HOME_INTRO_EDIT';
export const SET_SKIP_HOME_INTRO = 'SET_SKIP_HOME_INTRO';

export interface HomeIntroState {
  homeIntroList: any;
  isEdit: boolean;
  homeIntroEdit: any;
  skip: number;
}

interface setListHomeIntroAction {
  type: typeof SET_LIST_HOME_INTRO;
  payload: any;
}

interface setIsEditAction {
  type: typeof SET_IS_EDIT_HOME_INTRO;
  payload: any;
}

interface setHomeIntroEditAction {
  type: typeof SET_HOME_INTRO_EDIT;
  payload: any;
}

interface setSkipAction {
  type: typeof SET_SKIP_HOME_INTRO;
  payload: number;
}

export type HomeIntroAction = setListHomeIntroAction | setIsEditAction | setHomeIntroEditAction | setSkipAction;
