// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_view__YqUxD {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #000;
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: calc(50% + 200px) calc(50% - 78px);
}

.styles_wrapper__XlLpK {
  max-width: 360px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
  box-shadow: 0px 0px 15px 5px #fff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
}

.styles_wrapForm__MFumM {}

.styles_input__cus__R-Fvu {
  padding: 10px;
  border: 0;
  color: #000;
  background: #fff;
}

.styles_input__cus__R-Fvu:hover {
  outline: none;
  border: 0;
  box-shadow: none;
}

.styles_button__cus__t7eTz {
  margin-top: 20px;
  color: #000;
  box-sizing: border-box;
  background: #fff;
  padding: 10px 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: unset;
}

.styles_button__cus__t7eTz:hover {
  color: #fff;
  background: var(--color-main) !important;
}`, "",{"version":3,"sources":["webpack://./src/views/login/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,4BAA4B;EAC5B,sBAAsB;EACtB,uDAAuD;AACzD;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,gBAAgB;EAChB,iCAAiC;EACjC,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA,yBAAW;;AAEX;EACE,aAAa;EACb,SAAS;EACT,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,wCAAwC;AAC1C","sourcesContent":[".view {\n  width: 100vw;\n  height: 100vh;\n  position: relative;\n  background: #000;\n  background-repeat: no-repeat;\n  background-size: 300px;\n  background-position: calc(50% + 200px) calc(50% - 78px);\n}\n\n.wrapper {\n  max-width: 360px;\n  width: 100%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background: #000;\n  box-shadow: 0px 0px 15px 5px #fff;\n  padding: 20px;\n  box-sizing: border-box;\n  border-radius: 5px;\n}\n\n.wrapForm {}\n\n.input__cus {\n  padding: 10px;\n  border: 0;\n  color: #000;\n  background: #fff;\n}\n\n.input__cus:hover {\n  outline: none;\n  border: 0;\n  box-shadow: none;\n}\n\n.button__cus {\n  margin-top: 20px;\n  color: #000;\n  box-sizing: border-box;\n  background: #fff;\n  padding: 10px 30px;\n  font-weight: bold;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: unset;\n}\n\n.button__cus:hover {\n  color: #fff;\n  background: var(--color-main) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view": `styles_view__YqUxD`,
	"wrapper": `styles_wrapper__XlLpK`,
	"wrapForm": `styles_wrapForm__MFumM`,
	"input__cus": `styles_input__cus__R-Fvu`,
	"button__cus": `styles_button__cus__t7eTz`
};
export default ___CSS_LOADER_EXPORT___;
