import { homeTrendDesignApi } from "configs/api/homeTrendDesign"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setListHomeTrendDesign } from "store/actions/homeTrendDesignAction"
import { removeNullUndefined } from "utils"

export function useHomeTrendDesign() {

  const disPatch = useDispatch()

  const createHomeTrendDesign = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = homeTrendDesignApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const edithomeTrendDesign = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = homeTrendDesignApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteHomeTrendDesign = async (id) => {

    try {
      const promise = homeTrendDesignApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async (page) => {

    try {
      const promise = homeTrendDesignApi.getList(page)
      return await promise.then(async (res) => {
        disPatch(setListHomeTrendDesign(res.data))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    createHomeTrendDesign,
    edithomeTrendDesign,
    getDataList,
    deleteHomeTrendDesign,
  }
}