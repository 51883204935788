import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import HomeIntroContainer from 'modules/home/intro'

type Props = {
  //
};

const Home: React.FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Home Page Intro</title>
      </Helmet>
      <HomeIntroContainer />
    </>
  );
};

export default memo(Home);
