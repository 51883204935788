import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const homeIntroApi = {

	async create(data) {
		return instance.post(`home-intro`, data, await headersAuthToken());
	},

	async update(data) {
		return instance.put(`home-intro`, data, await headersAuthToken());
	},

	async delete(id) {
		return instance.delete(`home-intro?id=${id}`, await headersAuthToken());
	},

	async getList(page) {
		return instance.get(`home-intro/admin?page=${page}`, await headersAuthToken());
	},

};

