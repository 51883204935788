import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import HomeIntroFactoryContainer from 'modules/home/intro-factory'

type Props = {
  //
};

const Home: React.FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Xưởng thiết kế</title>
      </Helmet>
      <HomeIntroFactoryContainer />
    </>
  );
};

export default memo(Home);
