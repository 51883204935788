export const SET_COLLAPSE_SIDEBAR = 'SET_COLLAPSE_SIDEBAR';

export interface GlobalState {
  sidebarCollapse: boolean;
}

interface setCollapseSidebarAction {
  type: typeof SET_COLLAPSE_SIDEBAR;
  payload: boolean;
}

export type GlobalAction = setCollapseSidebarAction;
