import { SET_ABOUT_VALUE_EDIT, SET_IS_EDIT_ABOUT_VALUE, SET_LIST_ABOUT_VALUE, SET_SKIP_ABOUT_VALUE } from '../types/aboutValueTypes';

export const setListAboutValue = (homeIntros: any) => {
  return {
    type: SET_LIST_ABOUT_VALUE,
    payload: homeIntros,
  };
};


export const setIsEdit = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_ABOUT_VALUE,
    payload: isEdit,
  };
};


export const setAboutValueEdit = (homeIntro: any) => {
  return {
    type: SET_ABOUT_VALUE_EDIT,
    payload: homeIntro,
  };
};

export const setSkip = (skip: number) => {
  return {
    type: SET_SKIP_ABOUT_VALUE,
    payload: skip,
  };
};