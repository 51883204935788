import { Button, Card, Col, Row } from 'antd';
import Title from 'components/Title';
import React, { memo, useEffect } from 'react';
import { EditOutlined } from '@ant-design/icons';
import Create from './components/create';
import Detail from './components/detail';
import { RootState } from 'store/types';
import { useDispatch, useSelector } from 'react-redux';
import { useAboutVision } from 'hooks/useAboutVision';
import { setAboutVisionEdit, setIsEdit } from 'store/actions/aboutVisionAction';

type Props = {
  //
};

const HomeContainer: React.FC<Props> = () => {

  const { getDataList } = useAboutVision()
  const { skip, aboutVisionList, isEdit } = useSelector((state: RootState) => state?.aboutVision)
  const dispatch = useDispatch()

  useEffect(() => {
    getDataList(skip)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <div className="flex justify-between items-center mb-3">
            <Title level={4}>Tầm nhìn giá trị</Title>

            {
              (aboutVisionList && !isEdit) &&
              <Button
                icon={<EditOutlined />}
                size={'large'}
                style={{
                  background: '#ffc107',
                  border: '1px solid #ffc107',
                  marginRight: 10
                }}
                onClick={() => {
                  dispatch(setIsEdit(true))
                  dispatch(setAboutVisionEdit(aboutVisionList))
                }}
              >
                Chỉnh sửa
              </Button>
            }
          </div>
        </Col>

        <Col sm={!aboutVisionList || isEdit ? 16 : 24} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} className='overflow-auto'>
            <Detail />
          </Card>
        </Col>
        {
          (isEdit || !aboutVisionList) && (
            <Col sm={8} xs={24} style={{ marginBottom: 20 }}>
              <Card bordered={true} className='overflow-auto'>
                <Create />
              </Card>
            </Col>
          )
        }
      </Row>
    </>
  );
};

export default memo(HomeContainer);
