import { QuoteAction, QuoteState, SET_LIST_QUOTE, SET_SKIP_QUOTE } from '../types/quoteTypes';

const initialState: QuoteState = {
  quoteList: null,
  skip: 1
};

const QuoteReducer = (state = initialState, action: QuoteAction): QuoteState => {
  switch (action.type) {
    case SET_LIST_QUOTE:
      return {
        ...state,
        quoteList: action?.payload,
      };
    case SET_SKIP_QUOTE:
      return {
        ...state,
        skip: action?.payload,
      };
    default:
      return state;
  }
};

export default QuoteReducer;
