import React, { memo } from "react";
import { Button, Form, Input } from 'antd';
import { useAuthentication } from "hooks/useAuthentication";
import styles from './styles.module.css'

const Login = () => {
  const [form] = Form.useForm();
  const { login } = useAuthentication()

  const onFinish = async () => {
    const fileds = form.getFieldsValue()

    await login(fileds)
  };

  const initialValues = {
    // email: "admin@sdecor.com",
    // password: "12345678@ab"
  };


  return (
    <>
      <div className={styles.view}>
        <div className={styles.wrapper}>
          <Form
            initialValues={initialValues}
            name="basic"
            form={form}
            className={'wrap-form-login'}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input className={styles.input__cus} />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password className={styles.input__cus} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button className={styles.button__cus} type="primary" htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  )
}

export default memo(Login)