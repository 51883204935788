import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { keyLocalStorage } from '../constants'
import { removeLocalStorageServices, setLocalStorageServices } from 'utils/offlineServices'
import { LoginInterface } from 'types/interfaces'
import { authApi } from 'configs/api/auth';
import { AppRoute } from 'configs/router/config.router';

export function useAuthentication() {

    const history = useHistory();

    const login = async (values: LoginInterface) => {

        try {
            const promise = authApi.login(values)

            const loading = toast.loading("Pending...")

            return await promise.then(async (res) => {
                toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
                setLocalStorageServices(keyLocalStorage.login, res.data.token)
                history.push(AppRoute.setting)
                return {
                    success: true
                }
            }).catch(async (err) => {
                toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
                return {
                    ...err,
                    error: true
                }
            })
        } catch (err) {
            // 
        }
    }

    const validateToken = async () => {
        try {
            return await authApi.validateToken().then(async (res) => {
                return true
            }).catch((err) => {
                return false
            })
        } catch (err) {
            // 
        }
    }

    const logout = async () => {
        try {
            return await authApi.logout().then(async (res) => {
                removeLocalStorageServices(keyLocalStorage.login)
                return true
            }).catch((err) => {
                return false
            })
        } catch (err) {
            // 
        }
    }

    return {
        login,
        validateToken,
        logout
    }
}