import { BlogAction, BlogState, SET_IS_EDIT_BLOG_TYPE, SET_LIST_BLOG_TYPE, SET_BLOG_TYPE_EDIT } from '../types/blogTypes';

const initialState: BlogState = {
  blogTypeList: null,
  isEditBlogType: false,
  blogTypeEdit: null,
};

const BlogReducer = (state = initialState, action: BlogAction): BlogState => {
  switch (action.type) {
    case SET_LIST_BLOG_TYPE:
      return {
        ...state,
        blogTypeList: action?.payload,
      };
    case SET_IS_EDIT_BLOG_TYPE:
      return {
        ...state,
        isEditBlogType: action?.payload,
      };
    case SET_BLOG_TYPE_EDIT:
      return {
        ...state,
        blogTypeEdit: action?.payload,
      };
    default:
      return state;
  }
};

export default BlogReducer;
