import { SET_PROJECT_TYPE_EDIT, SET_IS_EDIT_PROJECT_TYPE, SET_LIST_PROJECT_TYPE } from '../types/projectTypes';

export const setListProjectType = (projectType: any) => {
  return {
    type: SET_LIST_PROJECT_TYPE,
    payload: projectType,
  };
};


export const setIsEditProjectType = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_PROJECT_TYPE,
    payload: isEdit,
  };
};


export const setProjectTypeEdit = (project: any) => {
  return {
    type: SET_PROJECT_TYPE_EDIT,
    payload: project,
  };
};