import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const bannerApi = {

	async create(data) {
		return instance.post(`banner`, data, await headersAuthToken());
	},

	async update(data) {
		return instance.put(`banner`, data, await headersAuthToken());
	},

	async delete(id) {
		return instance.delete(`banner?id=${id}`, await headersAuthToken());
	},

	async getList(page) {
		return instance.get(`banner/admin?page=${page}`, await headersAuthToken());
	},

};

