import {
  HomeOutlined,
  ProjectOutlined,
  AppstoreAddOutlined,
  GoldOutlined,
  WechatOutlined,
  // DashboardOutlined,
  GroupOutlined,
  TeamOutlined,
  RiseOutlined,
  ApartmentOutlined,
  SmileOutlined,
  BankOutlined,
  StarOutlined,
  CheckSquareOutlined,
  SketchOutlined,
  FileImageOutlined,
  UnorderedListOutlined,
  MailOutlined,
  CopyOutlined
} from '@ant-design/icons'
import type { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

export const useRouteTranslation = () => {
  const items: MenuItem[] = [
    // getItem('Dashboard', AppRoute.dashboard, <DashboardOutlined />),
    getItem('Trang chủ', AppRoute.homeGroup, <HomeOutlined />, [
      getItem('Giới thiệu', AppRoute.homeIntro, <GoldOutlined />),
      getItem('Thành quả', AppRoute.homeOperationalResults, <SketchOutlined />),
      getItem('Quy trình', AppRoute.homeDesignProcess, <CheckSquareOutlined />),
      getItem('Xưởng', AppRoute.homeIntroFactory, <BankOutlined />),
      getItem('Xu hướng thiết kế', AppRoute.homeTrendDesign, <StarOutlined />),
    ]),

    getItem('Giới thiệu', AppRoute.aboutGroup, <SmileOutlined />, [
      getItem('Dịch vụ cung cấp', AppRoute.aboutService, <ApartmentOutlined />),
      getItem('Tầm nhìn giá trị', AppRoute.aboutVision, <RiseOutlined />),
      getItem('Đội ngũ', AppRoute.aboutTeam, <TeamOutlined />),
      getItem('Giá trị mang lại', AppRoute.aboutValue, <GroupOutlined />),
    ]),

    getItem('Dự án', AppRoute.projectGroup, <ProjectOutlined />, [
      getItem('Danh sách', AppRoute.project, <UnorderedListOutlined />),
      getItem('Thêm', AppRoute.projectCreate, <AppstoreAddOutlined />),
      getItem('Loại dự án', AppRoute.projectType, <GoldOutlined />)
    ]),

    getItem('Blog', AppRoute.blogGroup, <CopyOutlined />, [
      getItem('Danh sách', AppRoute.blog, <UnorderedListOutlined />),
      getItem('Thêm', AppRoute.blogCreate, <AppstoreAddOutlined />),
      getItem('Loại blog', AppRoute.blogType, <GoldOutlined />)
    ]),

    getItem('Tư vấn', AppRoute.adviseGroup, <WechatOutlined />, [
      getItem('Danh sách', AppRoute.advise, <UnorderedListOutlined />),
      getItem('Thêm', AppRoute.adviseCreate, <AppstoreAddOutlined />),
      getItem('Loại tư vấn', AppRoute.adviseType, <GoldOutlined />)
    ]),

    getItem('Báo giá', AppRoute.quote, <MailOutlined />),
    getItem('Banner', AppRoute.banner, <FileImageOutlined />),
    getItem('Cài đặt', AppRoute.setting, <HomeOutlined />),
  ];

  return items
}

export const AppRoute = {
  all: '*',

  basic: '/',
  dashboard: '/dashboard',
  login: '/dang-nhap',
  quote: '/quote',

  homeGroup: '/trang-chu',
  homeIntro: '/trang-chu/gioi-thieu',
  homeOperationalResults: '/trang-chu/thanh-qua-hoat-dong',
  homeDesignProcess: '/trang-chu/quy-trinh-thiet-ke-thi-cong',
  homeIntroFactory: '/trang-chu/xuong-thiet-ke',
  homeTrendDesign: '/trang-chu/xu-huong-thiet-ke',

  aboutGroup: '/gioi-thieu',
  aboutService: '/gioi-thieu/dich-vu-cung-cap',
  aboutVision: '/gioi-thieu/tam-nhin-gia-tri',
  aboutTeam: '/gioi-thieu/doi-ngu',
  aboutValue: '/gioi-thieu/gia-tri-mang-lai',

  homeBanner: '/du-an',

  projectGroup: '/du-an',
  project: '/du-an',
  projectCreate: '/du-an/create',
  projectUpdate: '/du-an/edit/:id',
  projectUpdateId: (id: string) => { return `/du-an/edit/${id}` },
  projectDetail: '/du-an/detail/:id',
  projectDetailId: (id: string) => { return `/du-an/detail/${id}` },
  projectType: '/du-an/type',

  blogGroup: '/blog',
  blog: '/blog',
  blogCreate: '/blog/create',
  blogUpdate: '/blog/edit/:id',
  blogUpdateId: (id: string) => { return `/blog/edit/${id}` },
  blogDetail: '/blog/detail/:id',
  blogDetailId: (id: string) => { return `/blog/detail/${id}` },
  blogType: '/blog/type',

  adviseGroup: '/tu-van',
  advise: '/tu-van',
  adviseCreate: '/tu-van/create',
  adviseUpdate: '/tu-van/edit/:id',
  adviseUpdateId: (id: string) => { return `/tu-van/edit/${id}` },
  adviseDetail: '/tu-van/detail/:id',
  adviseDetailId: (id: string) => { return `/tu-van/detail/${id}` },
  adviseType: '/tu-van/type',

  setting: '/cai-dat',

  banner: '/banner'
}