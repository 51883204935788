import { AboutServiceAction, AboutServiceState, SET_ABOUT_SERVICE_EDIT, SET_IS_EDIT_ABOUT_SERVICE, SET_LIST_ABOUT_SERVICE, SET_SKIP_ABOUT_SERVICE } from '../types/aboutServiceTypes';

const initialState: AboutServiceState = {
  aboutServiceList: null,
  isEdit: false,
  aboutServiceEdit: null,
  skip: 1
};

const AboutServiceReducer = (state = initialState, action: AboutServiceAction): AboutServiceState => {
  switch (action.type) {
    case SET_LIST_ABOUT_SERVICE:
      return {
        ...state,
        aboutServiceList: action?.payload,
      };
    case SET_IS_EDIT_ABOUT_SERVICE:
      return {
        ...state,
        isEdit: action?.payload,
      };
    case SET_ABOUT_SERVICE_EDIT:
      return {
        ...state,
        aboutServiceEdit: action?.payload,
      };
    case SET_SKIP_ABOUT_SERVICE:
      return {
        ...state,
        skip: action?.payload,
      };
    default:
      return state;
  }
};

export default AboutServiceReducer;
