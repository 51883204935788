export const SET_LIST_HOME_DESIGN_PROCESS = 'SET_LIST_HOME_DESIGN_PROCESS';
export const SET_IS_EDIT_HOME_DESIGN_PROCESS = 'SET_IS_EDIT_HOME_DESIGN_PROCESS';
export const SET_HOME_DESIGN_PROCESS_EDIT = 'SET_HOME_DESIGN_PROCESS_EDIT';
export const SET_SKIP_HOME_DESIGN_PROCESS = 'SET_SKIP_HOME_DESIGN_PROCESS';

export interface HomeDesignProcessState {
  homeDesignProcessList: any;
  isEdit: boolean;
  homeDesignProcessEdit: any;
  skip: number;
}

interface setListHomeDesignProcessAction {
  type: typeof SET_LIST_HOME_DESIGN_PROCESS;
  payload: any;
}

interface setIsEditAction {
  type: typeof SET_IS_EDIT_HOME_DESIGN_PROCESS;
  payload: any;
}

interface setHomeDesignProcessEditAction {
  type: typeof SET_HOME_DESIGN_PROCESS_EDIT;
  payload: any;
}

interface setSkipAction {
  type: typeof SET_SKIP_HOME_DESIGN_PROCESS;
  payload: number;
}

export type HomeDesignProcessAction = setListHomeDesignProcessAction | setIsEditAction | setHomeDesignProcessEditAction | setSkipAction;
