import { BannerAction, BannerState, SET_BANNER_EDIT, SET_IS_EDIT_BANNER, SET_LIST_BANNER, SET_SKIP_BANNER } from '../types/bannerTypes';

const initialState: BannerState = {
  bannerList: null,
  isEdit: false,
  bannerEdit: null,
  skip: 1
};

const BannerReducer = (state = initialState, action: BannerAction): BannerState => {
  switch (action.type) {
    case SET_LIST_BANNER:
      return {
        ...state,
        bannerList: action?.payload,
      };
    case SET_IS_EDIT_BANNER:
      return {
        ...state,
        isEdit: action?.payload,
      };
    case SET_BANNER_EDIT:
      return {
        ...state,
        bannerEdit: action?.payload,
      };
    case SET_SKIP_BANNER:
      return {
        ...state,
        skip: action?.payload,
      };
    default:
      return state;
  }
};

export default BannerReducer;
