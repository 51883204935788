import React, { memo } from 'react';
import { Typography } from 'antd';
import { TitleProps } from 'antd/es/typography/Title';

type Props = {
  children: React.ReactNode
} & TitleProps & React.RefAttributes<HTMLElement>;

const { Title } = Typography;

const TitleAntd: React.FC<Props> = (props) => {
  const { children } = props;
  return (
    <>
      <Title {...props}>
        {children}
      </Title>
    </>
  );
};

export default memo(TitleAntd);
