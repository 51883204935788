import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const settingApi = {
	async update(data) {
		return instance.put(`setting`, data, await headersAuthToken());
	},

	async get() {
		return instance.get(`setting`);
	},

};

