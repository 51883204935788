import { HomeOperationalResultsAction, HomeOperationalResultsState, SET_HOME_OPERATIONAL_RESULTS_EDIT, SET_IS_EDIT_HOME_OPERATIONAL_RESULTS, SET_LIST_HOME_OPERATIONAL_RESULTS, SET_SKIP_HOME_OPERATIONAL_RESULTS } from '../types/homeOperationalResultsTypes';

const initialState: HomeOperationalResultsState = {
  homeOperationalResultsList: null,
  isEdit: false,
  homeOperationalResultsEdit: null,
  skip: 1
};

const HomeOperationalResultsReducer = (state = initialState, action: HomeOperationalResultsAction): HomeOperationalResultsState => {
  switch (action.type) {
    case SET_LIST_HOME_OPERATIONAL_RESULTS:
      return {
        ...state,
        homeOperationalResultsList: action?.payload,
      };
    case SET_IS_EDIT_HOME_OPERATIONAL_RESULTS:
      return {
        ...state,
        isEdit: action?.payload,
      };
    case SET_HOME_OPERATIONAL_RESULTS_EDIT:
      return {
        ...state,
        homeOperationalResultsEdit: action?.payload,
      };
    case SET_SKIP_HOME_OPERATIONAL_RESULTS:
      return {
        ...state,
        skip: action?.payload,
      };
    default:
      return state;
  }
};

export default HomeOperationalResultsReducer;
