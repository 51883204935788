import Create from 'modules/blog/create';
import React, { memo } from 'react';

type Props = {
  //
};

const CreateBlog: React.FC<Props> = () => {
  return (
    <>
      <Create />
    </>
  );
};

export default memo(CreateBlog);
