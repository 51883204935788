export const setLocalStorageServices = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
    return true
}

export const removeLocalStorageServices = (key: string) => {
    localStorage.removeItem(key)
    return true
}

export const getLocalStorageServices = (key: string) => {
    return JSON.parse(localStorage.getItem(key))
}