import React, { memo } from 'react';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Switch, Table, Tooltip } from 'antd';
import { useHomeIntro } from 'hooks/useHomeIntro';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { setHomeIntroEdit, setIsEdit } from 'store/actions/homeIntroAction';

type Props = {
  // 
};

const { confirm } = Modal;

const TableComponent: React.FC<Props> = () => {

  const dispatch = useDispatch()
  const { homeIntroList, skip } = useSelector((state: RootState) => state.homeIntro)
  const { getDataList, deleteHomeIntro, edithomeIntro } = useHomeIntro()

  const onChangePagination = (_skip: number) => { }

  const showConfirmDelete = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <Button>Are you sure?</Button>,
      onOk() {
        deleteHomeIntro(id).then((res) => {
          getDataList(skip)
        });
      },
      onCancel() {
      },
    });
  };

  const onUpdateStatusHomeIntro = async (id, active) => {
    await edithomeIntro({
      id: id,
      active: !active
    }).then((res) => {
      getDataList(skip)
    })
  }


  const columns = [
    {
      title: 'Tiêu đề',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row, index) => {
        return <p>{row.title}</p>;
      },
    },
    {
      title: 'Nội dung',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row, index) => {
        return <p>{row.content}</p>;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'id',
      width: '25%',
      render: (cell, row) => {
        return (
          <div className="flex-align-items">
            <Switch
              checked={row?.active}
              onChange={(checked, event) => {
                onUpdateStatusHomeIntro(row._id, row.active);
              }}
            />
            <span style={{ marginLeft: 10 }}>{row?.active ? 'Active' : 'Inactive'}</span>
          </div>
        );
      },
    },
    {
      title: 'Hành động',
      dataIndex: 'id',
      width: '5%',
      render: (cell, row, index) => {
        return (
          <Space>
            <Tooltip title={'Sửa'}>
              <div
                onClick={() => {
                  dispatch(setIsEdit(true))
                  dispatch(setHomeIntroEdit(row))
                }}
              >
                <Button icon={<EditOutlined />} />
              </div>
            </Tooltip>
            <Tooltip title={'Delete'}>
              <Button
                onClick={() => {
                  showConfirmDelete(row._id)
                }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        // @ts-ignore
        dataSource={homeIntroList?.data || []}
        columns={columns}
        style={{ margin: 0 }}
        className="table_list"
        pagination={{
          position: ['bottomCenter'],
          current: parseFloat((1).toString()),
          total: 1,
          pageSize: 12,
          onChange: (skip: number) => onChangePagination(skip),
          showSizeChanger: false,
        }}
      />
    </>
  );
};

export default memo(TableComponent);
