import { blogTypeApi } from "configs/api/blog-type"
import { useDispatch } from "react-redux"
import { setListBlogType } from "store/actions/blogAction"
import { toast } from "react-toastify"

export function useBlogType() {

  const disPatch = useDispatch()

  const createBlog = async (values) => {

    try {
      const promise = blogTypeApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const editBlog = async (values) => {

    try {
      const promise = blogTypeApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteBlog = async (id) => {

    try {
      const promise = blogTypeApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async () => {

    try {
      const promise = blogTypeApi.getList()
      return await promise.then(async (res) => {
        disPatch(setListBlogType(res.data))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    getDataList,
    deleteBlog,
    createBlog,
    editBlog
  }
}