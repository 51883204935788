import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import HomeTrendDesignContainer from 'modules/home/trend-design'

type Props = {
  //
};

const Home: React.FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Xu hướng thiết kế</title>
      </Helmet>
      <HomeTrendDesignContainer />
    </>
  );
};

export default memo(Home);
