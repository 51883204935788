import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import AboutVisionContainer from 'modules/about/vision'

type Props = {
  //
};

const Home: React.FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Tầm nhìn giá trị</title>
      </Helmet>
      <AboutVisionContainer />
    </>
  );
};

export default memo(Home);
