import { blogApi } from "configs/api/blog"
import { toast } from "react-toastify"

export function useBlog() {

  const createBlog = async (values) => {

    try {
      const promise = blogApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          id: res.data,
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const viewBlog = async (id) => {

    try {
      const promise = blogApi.getDetail(id)

      return await promise.then(async (res) => {
        return {
          data: res.data,
          success: true
        }
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getList = async (page) => {

    try {
      const promise = blogApi.getList(page)

      return await promise.then(async (res) => {
        return {
          data: res.data,
          success: true
        }
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const editBlog = async (values) => {

    try {
      const promise = blogApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteBlog = async (id) => {

    try {
      const promise = blogApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    createBlog,
    viewBlog,
    getList,
    editBlog,
    deleteBlog
  }
}