import { RcFile } from "antd/es/upload";
import copy from 'copy-to-clipboard'

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getBase64_NoResize = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      // const base64String = resolve(event.target.result);
    };

    reader.readAsDataURL(file);
  });

export const removeNullUndefined = (obj) => {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
}

export const copyClipboard = (value: any) => {

  copy(value);

  return true;
}