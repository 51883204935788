export const SET_LIST_ABOUT_VISION = 'SET_LIST_ABOUT_VISION';
export const SET_IS_EDIT_ABOUT_VISION = 'SET_IS_EDIT_ABOUT_VISION';
export const SET_ABOUT_VISION_EDIT = 'SET_ABOUT_VISION_EDIT';
export const SET_SKIP_ABOUT_VISION = 'SET_SKIP_ABOUT_VISION';

export interface AboutVisionState {
  aboutVisionList: any;
  isEdit: boolean;
  aboutVisionEdit: any;
  skip: number;
}

interface setListAboutVisionAction {
  type: typeof SET_LIST_ABOUT_VISION;
  payload: any;
}

interface setIsEditAction {
  type: typeof SET_IS_EDIT_ABOUT_VISION;
  payload: any;
}

interface setAboutVisionEditAction {
  type: typeof SET_ABOUT_VISION_EDIT;
  payload: any;
}

interface setSkipAction {
  type: typeof SET_SKIP_ABOUT_VISION;
  payload: number;
}

export type AboutVisionAction = setListAboutVisionAction | setIsEditAction | setAboutVisionEditAction | setSkipAction;
