import React, { memo } from 'react';
import ListBlogContainer from 'modules/blog/list'

type Props = {
  //
};

const ListBlog: React.FC<Props> = () => {
  return (
    <>
      <ListBlogContainer />
    </>
  );
};

export default memo(ListBlog);
