import { HomeIntroAction, HomeIntroState, SET_HOME_INTRO_EDIT, SET_IS_EDIT_HOME_INTRO, SET_LIST_HOME_INTRO, SET_SKIP_HOME_INTRO } from '../types/homeIntroTypes';

const initialState: HomeIntroState = {
  homeIntroList: null,
  isEdit: false,
  homeIntroEdit: null,
  skip: 1
};

const HomeIntroReducer = (state = initialState, action: HomeIntroAction): HomeIntroState => {
  switch (action.type) {
    case SET_LIST_HOME_INTRO:
      return {
        ...state,
        homeIntroList: action?.payload,
      };
    case SET_IS_EDIT_HOME_INTRO:
      return {
        ...state,
        isEdit: action?.payload,
      };
    case SET_HOME_INTRO_EDIT:
      return {
        ...state,
        homeIntroEdit: action?.payload,
      };
    case SET_SKIP_HOME_INTRO:
      return {
        ...state,
        skip: action?.payload,
      };
    default:
      return state;
  }
};

export default HomeIntroReducer;
