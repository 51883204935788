import { GlobalAction, GlobalState, SET_COLLAPSE_SIDEBAR } from '../types/globalTypes';

const initialState: GlobalState = {
  sidebarCollapse: false,
};

const GlobalReducer = (state = initialState, action: GlobalAction): GlobalState => {
  switch (action.type) {
    case SET_COLLAPSE_SIDEBAR:
      return {
        ...state,
        sidebarCollapse: action?.payload,
      };
    default:
      return state;
  }
};

export default GlobalReducer;
