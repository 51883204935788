import { SET_HOME_INTRO_EDIT, SET_IS_EDIT_HOME_INTRO, SET_LIST_HOME_INTRO, SET_SKIP_HOME_INTRO } from '../types/homeIntroTypes';

export const setListHomeIntro = (homeIntros: any) => {
  return {
    type: SET_LIST_HOME_INTRO,
    payload: homeIntros,
  };
};


export const setIsEdit = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_HOME_INTRO,
    payload: isEdit,
  };
};


export const setHomeIntroEdit = (homeIntro: any) => {
  return {
    type: SET_HOME_INTRO_EDIT,
    payload: homeIntro,
  };
};

export const setSkip = (skip: number) => {
  return {
    type: SET_SKIP_HOME_INTRO,
    payload: skip,
  };
};