// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__EIsds {}

.styles_sider__Af73s {
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
}

.styles_wrapper__EIsds .styles_menu__H4js3 {
  height: calc(100% - 64px);
  overflow-y: auto;
  background: #000;
}

.styles_wrapper__EIsds .styles_ant-menu-title-content__AxQsG {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.styles_logo__VIWQO {
  height: 32px;
  margin: 16px;
  font-size: 24px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_imgLogo__MRVPn {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Sider/styles.module.css"],"names":[],"mappings":"AAAA,wBAAU;;AAEV;EACE,cAAc;EACd,aAAa;EACb,0BAA0B;EAC1B,OAAO;EACP,MAAM;EACN,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".wrapper {}\n\n.sider {\n  overflow: auto;\n  height: 100vh;\n  position: fixed !important;\n  left: 0;\n  top: 0;\n  bottom: 0;\n}\n\n.wrapper .menu {\n  height: calc(100% - 64px);\n  overflow-y: auto;\n  background: #000;\n}\n\n.wrapper .ant-menu-title-content {\n  text-transform: uppercase;\n  font-weight: 700;\n  letter-spacing: 1px;\n}\n\n.logo {\n  height: 32px;\n  margin: 16px;\n  font-size: 24px;\n  font-weight: 900;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.imgLogo {\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__EIsds`,
	"sider": `styles_sider__Af73s`,
	"menu": `styles_menu__H4js3`,
	"ant-menu-title-content": `styles_ant-menu-title-content__AxQsG`,
	"logo": `styles_logo__VIWQO`,
	"imgLogo": `styles_imgLogo__MRVPn`
};
export default ___CSS_LOADER_EXPORT___;
