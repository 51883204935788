export const SET_LIST_QUOTE = 'SET_LIST_QUOTE';
export const SET_SKIP_QUOTE = 'SET_SKIP_QUOTE';

export interface QuoteState {
  quoteList: any;
  skip: number;
}

interface setListQuoteAction {
  type: typeof SET_LIST_QUOTE;
  payload: any;
}

interface setSkipAction {
  type: typeof SET_SKIP_QUOTE;
  payload: number;
}

export type QuoteAction = setListQuoteAction | setSkipAction;
