import { homeIntroApi } from "configs/api/homeIntro"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setListHomeIntro } from "store/actions/homeIntroAction"
import { removeNullUndefined } from "utils"

export function useHomeIntro() {

  const disPatch = useDispatch()

  const createHomeIntro = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = homeIntroApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const edithomeIntro = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = homeIntroApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteHomeIntro = async (id) => {

    try {
      const promise = homeIntroApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async (page) => {

    try {
      const promise = homeIntroApi.getList(page)
      return await promise.then(async (res) => {
        disPatch(setListHomeIntro(res.data))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    createHomeIntro,
    edithomeIntro,
    getDataList,
    deleteHomeIntro,
  }
}