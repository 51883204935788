import React, { memo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, UploadFile } from 'antd';
import { getBase64 } from 'utils';
import ImgCrop from 'antd-img-crop';
import { RcFile, UploadProps } from 'antd/es/upload';

type Props = {
  fileList: UploadFile[],
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>,
  aspect?: number,
};


const UploadFileComponent: React.FC<Props> = ({ fileList, setFileList, aspect = 2000 / 706 }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <ImgCrop fillColor='transparent' rotationSlider aspect={aspect}>
        <Upload
          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {fileList.length === 1 ? null : uploadButton}
        </Upload>
      </ImgCrop>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default memo(UploadFileComponent);
