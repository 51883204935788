import { quoteApi } from "configs/api/quote"
import { useDispatch } from "react-redux"
import { setListQuote } from "store/actions/quoteAction"
import { toast } from "react-toastify"

export function useQuote() {

  const disPatch = useDispatch()

  const seenQuote = async (id) => {

    try {
      const promise = quoteApi.seen(id)

      return await promise.then(async (res) => {
        return {
          success: true
        }
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteQuote = async (id) => {

    try {
      const promise = quoteApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async (page) => {

    try {
      const promise = quoteApi.getList(page)
      return await promise.then(async (res) => {
        disPatch(setListQuote(res.data))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    getDataList,
    deleteQuote,
    seenQuote
  }
}