import { uploadFileheadersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const uploadApi = {

	async upload(file) {
		return instance.post(`image/upload`, file, await uploadFileheadersAuthToken());
	},

	async getFile(id) {
		return instance.get(`image/${id}`, {
			responseType: 'blob',
		});
	},
};

