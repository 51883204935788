import { AboutValueAction, AboutValueState, SET_ABOUT_VALUE_EDIT, SET_IS_EDIT_ABOUT_VALUE, SET_LIST_ABOUT_VALUE, SET_SKIP_ABOUT_VALUE } from '../types/aboutValueTypes';

const initialState: AboutValueState = {
  aboutValueList: null,
  isEdit: false,
  aboutValueEdit: null,
  skip: 1
};

const AboutValueReducer = (state = initialState, action: AboutValueAction): AboutValueState => {
  switch (action.type) {
    case SET_LIST_ABOUT_VALUE:
      return {
        ...state,
        aboutValueList: action?.payload,
      };
    case SET_IS_EDIT_ABOUT_VALUE:
      return {
        ...state,
        isEdit: action?.payload,
      };
    case SET_ABOUT_VALUE_EDIT:
      return {
        ...state,
        aboutValueEdit: action?.payload,
      };
    case SET_SKIP_ABOUT_VALUE:
      return {
        ...state,
        skip: action?.payload,
      };
    default:
      return state;
  }
};

export default AboutValueReducer;
