import React, { memo, useEffect, useState } from 'react';
import UploadFileComponent from 'components/UploadFile/Single'
import BraftEditor from 'braft-editor';
import { Button, Card, Col, Form, Input, Row, Select, UploadFile } from 'antd';
import RickTextEditorForm from 'components/RickTextEditorForm';
import { useAdvise } from 'hooks/useAdvise';
import { useAdviseType } from 'hooks/useAdviseType';
import { RootState } from 'store/types';
import { useSelector } from 'react-redux';
import { useUpload } from 'hooks/useUpload';
import { useHistory } from 'react-router-dom';
import { AppRoute } from 'configs/router/config.router';
import ImageAnt from 'components/ImageAnt';

type Props = {
  isEdit?: boolean,
  post?: any
};

const FormContainer: React.FC<Props> = ({ isEdit = false, post }) => {

  const [fileList, setFileList] = useState<UploadFile[]>([])
  const { adviseTypeList } = useSelector((state: RootState) => state.advise)
  const { createAdvise, editAdvise } = useAdvise()
  const [form] = Form.useForm();
  const { upload } = useUpload()
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const { getDataList } = useAdviseType()

  const onFinish = async () => {
    const fields = form.getFieldsValue()

    if (isEdit) {
      setLoading(true)
      let _thumbnail = post.thumbnail
      if (fileList.length >= 1) {
        const formData = new FormData();
        formData.append('image', fileList[0].originFileObj);
        await upload(formData).then(async (res) => {
          _thumbnail = res;
        })
      }
      const _content = await fields.content.toHTML()

      await editAdvise({
        ...fields,
        id: post?._id,
        content: _content,
        thumbnail: _thumbnail
      }).then((res) => {
        history.push(`${AppRoute.adviseDetailId(post._id)}`)
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      })
    } else {
      let _content = fields.content.toHTML()
      if (fileList.length >= 1) {
        setLoading(true)
        const formData = new FormData();
        formData.append('image', fileList[0].originFileObj);
        await upload(formData).then(async (res) => {
          await createAdvise({
            ...fields,
            thumbnail: res,
            content: _content,
          }).then((id) => {
            setLoading(false)
            history.push(`${AppRoute.adviseDetailId(id.id)}`)
          })
        })
      }
    }
  }

  useEffect(() => {
    getDataList()
    // eslint-disable-next-line
  }, [])

  const initValue = isEdit ? {
    name: post.name,
    shortDescription: post.shortDescription,
    type: post?.type?._id,
    content: BraftEditor.createEditorState(post.content)
  } : {}

  return (
    <>
      <Form
        name="normal_reviews"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initValue}
      >
        <Row gutter={24}>
          <Col sm={12} xs={24} style={{ marginBottom: 20 }}>
            <Card bordered={true} className='overflow-auto h-full'>
              <Form.Item name="name" label="Tên dự án" rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}>
                <Input placeholder="Tên dự án" />
              </Form.Item>
              <Form.Item name="shortDescription" label="Mô tả ngắn" rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}>
                <Input placeholder="Mô tả ngắn" />
              </Form.Item>
              <Form.Item
                label="Loại bài viết"
                name={'type'}
                rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}
              >
                <Select style={{ width: '100%' }} placeholder="Loại bài viết">
                  {adviseTypeList?.data?.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item._id}>
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Card>
          </Col>
          <Col sm={12} xs={24} style={{ marginBottom: 20 }}>
            <Card bordered={true} className='overflow-auto h-full'>
              {
                isEdit
                  ? <div className='mb-3'>
                    <ImageAnt
                      id={post?.thumbnail}
                      options={{
                        width: 100,
                      }}
                    />
                  </div>
                  : <></>
              }
              <UploadFileComponent fileList={fileList} setFileList={setFileList} aspect={768 / 576} />
            </Card>
          </Col>

          <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
            <Card bordered={true} className='overflow-auto h-full'>
              <Form.Item
                label={'Nội dung'}
                name="content"
                validateTrigger={'onBlur'}
                validateFirst
                rules={[
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (value.isEmpty()) {
                        return Promise.reject('Không được bỏ trống');
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <RickTextEditorForm
                  value={form.getFieldValue('content')}
                  handleChangeText={(val) => {
                    form.setFieldsValue({
                      content: val,
                    })
                  }
                  }
                />
              </Form.Item>
            </Card>
          </Col>
          <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
            <Card bordered={true} className='overflow-auto'>
              <Form.Item>
                {isEdit ? (
                  <div className='flex items-center'>
                    <div className='mr-2 py-1 px-3 h-full bg-red-600 cursor-pointer text-white rounded' onClick={() => history.push(AppRoute.adviseDetailId(post?._id))}>
                      Cancel
                    </div>
                    <Button type="primary" htmlType='submit' loading={loading}>
                      Update
                    </Button>
                  </div>
                ) : (
                  <Button type="primary" htmlType='submit' loading={loading}>
                    Thêm
                  </Button>
                )}
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default memo(FormContainer);
