// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__ZJ-2l {
  width: 100%;
  height: 100%;
  position: relative;
}

.styles_content__I4ajt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

.styles_text__l-ikR {
  font-size: 64px;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-bottom: 5px;
}

.styles_description__-XOyU {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #000;
  margin-bottom: 50px;
}

.styles_btnBackHome__FeDyp {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #000;
  padding: 10px 50px;
  box-sizing: border-box;
  border: 1px solid transparent;
  outline: none;
  border-image-slice: 1;
  background: var(--color-main);
  cursor: pointer;
}

.styles_btnBackHome__FeDyp:hover {
  background: #1A3DF7;
}`, "",{"version":3,"sources":["webpack://./src/components/NotFound/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAEhB,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,6BAA6B;EAC7B,aAAa;EACb,qBAAqB;EACrB,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n\n.content {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 20px;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.text {\n  font-size: 64px;\n  font-weight: 600;\n  text-align: center;\n  color: #000;\n  margin-bottom: 5px;\n}\n\n.description {\n  font-weight: 400;\n  margin-bottom: 16px;\n  font-size: 16px;\n  text-align: center;\n  color: #000;\n  margin-bottom: 50px;\n}\n\n.btnBackHome {\n  font-size: 16px;\n  font-weight: 600;\n  text-align: center;\n  color: #000;\n  padding: 10px 50px;\n  box-sizing: border-box;\n  border: 1px solid transparent;\n  outline: none;\n  border-image-slice: 1;\n  background: var(--color-main);\n  cursor: pointer;\n}\n\n.btnBackHome:hover {\n  background: #1A3DF7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__ZJ-2l`,
	"content": `styles_content__I4ajt`,
	"text": `styles_text__l-ikR`,
	"description": `styles_description__-XOyU`,
	"btnBackHome": `styles_btnBackHome__FeDyp`
};
export default ___CSS_LOADER_EXPORT___;
