import { Card, Col, Row } from 'antd';
import Title from 'components/Title';
import React, { memo, useEffect } from 'react';
import Create from './components/create';
import Table from './components/table';
import { RootState } from 'store/types';
import { useSelector } from 'react-redux';
import { useHomeTrendDesign } from 'hooks/useHomeTrendDesign';

type Props = {
  //
};

const HomeContainer: React.FC<Props> = () => {

  const { getDataList } = useHomeTrendDesign()
  const { skip, homeTrendDesignList, isEdit } = useSelector((state: RootState) => state?.homeTrendDesign)

  useEffect(() => {
    getDataList(skip)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <Title level={4}>Xu hướng thiết kế</Title>
        </Col>

        <Col sm={homeTrendDesignList?.data?.length < 4 || isEdit ? 16 : 24} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} className='overflow-auto'>
            <Table />
          </Card>
        </Col>
        {
          (homeTrendDesignList?.data?.length < 4 || isEdit) &&
          <Col sm={8} xs={24} style={{ marginBottom: 20 }}>
            <Card bordered={true} className='overflow-auto'>
              <Create />
            </Card>
          </Col>
        }
      </Row>
    </>
  );
};

export default memo(HomeContainer);
