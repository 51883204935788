import { SET_ADVISE_TYPE_EDIT, SET_IS_EDIT_ADVISE_TYPE, SET_LIST_ADVISE_TYPE } from '../types/adviseTypes';

export const setListAdviseType = (adviseType: any) => {
  return {
    type: SET_LIST_ADVISE_TYPE,
    payload: adviseType,
  };
};


export const setIsEditAdviseType = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_ADVISE_TYPE,
    payload: isEdit,
  };
};


export const setAdviseTypeEdit = (advise: any) => {
  return {
    type: SET_ADVISE_TYPE_EDIT,
    payload: advise,
  };
};