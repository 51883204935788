import { SET_HOME_INTRO_FACTORY_EDIT, SET_IS_EDIT_HOME_INTRO_FACTORY, SET_LIST_HOME_INTRO_FACTORY, SET_SKIP_HOME_INTRO_FACTORY } from '../types/homeIntroFactoryTypes';

export const setListHomeIntroFactory = (homeIntroFactorys: any) => {
  return {
    type: SET_LIST_HOME_INTRO_FACTORY,
    payload: homeIntroFactorys,
  };
};


export const setIsEdit = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_HOME_INTRO_FACTORY,
    payload: isEdit,
  };
};


export const setHomeIntroFactoryEdit = (homeIntroFactory: any) => {
  return {
    type: SET_HOME_INTRO_FACTORY_EDIT,
    payload: homeIntroFactory,
  };
};

export const setSkip = (skip: number) => {
  return {
    type: SET_SKIP_HOME_INTRO_FACTORY,
    payload: skip,
  };
};