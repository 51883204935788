export const SET_LIST_ABOUT_SERVICE = 'SET_LIST_ABOUT_SERVICE';
export const SET_IS_EDIT_ABOUT_SERVICE = 'SET_IS_EDIT_ABOUT_SERVICE';
export const SET_ABOUT_SERVICE_EDIT = 'SET_ABOUT_SERVICE_EDIT';
export const SET_SKIP_ABOUT_SERVICE = 'SET_SKIP_ABOUT_SERVICE';

export interface AboutServiceState {
  aboutServiceList: any;
  isEdit: boolean;
  aboutServiceEdit: any;
  skip: number;
}

interface setListAboutServiceAction {
  type: typeof SET_LIST_ABOUT_SERVICE;
  payload: any;
}

interface setIsEditAction {
  type: typeof SET_IS_EDIT_ABOUT_SERVICE;
  payload: any;
}

interface setAboutServiceEditAction {
  type: typeof SET_ABOUT_SERVICE_EDIT;
  payload: any;
}

interface setSkipAction {
  type: typeof SET_SKIP_ABOUT_SERVICE;
  payload: number;
}

export type AboutServiceAction = setListAboutServiceAction | setIsEditAction | setAboutServiceEditAction | setSkipAction;
