import { settingApi } from "configs/api/setting"
import { toast } from "react-toastify"

export function useSetting() {

  const editSetting = async (id, _values) => {

    try {
      const promise = settingApi.update({ id, ..._values })
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getSetting = async () => {

    try {
      const promise = settingApi.get()
      return await promise.then(async (res) => {
        return res.data
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    getSetting,
    editSetting,
  }
}