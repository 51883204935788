import { SET_BLOG_TYPE_EDIT, SET_IS_EDIT_BLOG_TYPE, SET_LIST_BLOG_TYPE } from '../types/blogTypes';

export const setListBlogType = (blogType: any) => {
  return {
    type: SET_LIST_BLOG_TYPE,
    payload: blogType,
  };
};


export const setIsEditBlogType = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_BLOG_TYPE,
    payload: isEdit,
  };
};


export const setBlogTypeEdit = (blog: any) => {
  return {
    type: SET_BLOG_TYPE_EDIT,
    payload: blog,
  };
};