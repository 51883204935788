import { Card, Col, Row } from 'antd';
import ImageAnt from 'components/ImageAnt';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';

type Props = {
  // 
};

const DetailComponent: React.FC<Props> = () => {

  const { aboutVisionList } = useSelector((state: RootState) => state.aboutVision)

  return (
    <>
      <Row gutter={24}>
        <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} title="Tiêu đề" className='overflow-auto'>
            {aboutVisionList?.title}
          </Card>
        </Col>
        <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} title="Giá trị" className='overflow-auto'>
            {
              aboutVisionList?.vision?.map((item, index) => (
                <p className='mb-2' key={index}>- {item}</p>
              ))
            }
          </Card>
        </Col>
        <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} title="Ảnh" className='overflow-auto'>
            <ImageAnt id={aboutVisionList?.image ?? ''} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default memo(DetailComponent);
