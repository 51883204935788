import React, { memo, useEffect, useState } from 'react';
import { Button, Form, InputNumber } from 'antd';
import { setHomeOperationalResultsEdit, setIsEdit } from 'store/actions/homeOperationalResultsAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { useHomeOperationalResults } from 'hooks/useHomeOperationalResults';
import TextArea from 'antd/es/input/TextArea';

type Props = {
  // 
};

const CreateHomeOperationalResults: React.FC<Props> = () => {

  const dispatch = useDispatch()
  const { isEdit, homeOperationalResultsEdit, skip } = useSelector((state: RootState) => state.homeOperationalResults)
  const { createHomeOperationalResults, edithomeOperationalResults, getDataList } = useHomeOperationalResults()
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm();

  const onFinish = async () => {
    const fields = form.getFieldsValue();
    setLoading(true)

    const description = fields.description.replace(/\n/g, '<br/>')

    try {
      if (isEdit) {
        await edithomeOperationalResults(
          {
            id: homeOperationalResultsEdit._id,
            ...fields,
            description: description,
          }
        )
          .then((res) => {
            getDataList(skip)
            dispatch(setHomeOperationalResultsEdit(null))
            dispatch(setIsEdit(false))
            setLoading(false)
          })
      } else {
        await createHomeOperationalResults({ ...fields, description: description })
          .then((res) => {
            getDataList(skip)
            setLoading(false)
          })
      }
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    form.resetFields();
    if (isEdit) {
      form.setFieldsValue({
        description: homeOperationalResultsEdit.description.replace(/<br\s*\/?>/gi, "\n"),
        experience_number: homeOperationalResultsEdit.experience_number,
        project_number: homeOperationalResultsEdit.project_number,
        scale_number: homeOperationalResultsEdit.scale_number
      });
    }
    // eslint-disable-next-line
  }, [isEdit, homeOperationalResultsEdit])

  return (
    <>
      <Form
        name="normal_reviews"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item name="description" label="Mô tả" rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}>
          <TextArea rows={10} placeholder="Mô tả" />
        </Form.Item>
        <Form.Item name="experience_number" label="Số năm kinh nghiệm" rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}>
          <InputNumber className='w-full' placeholder="Ex: 6" />
        </Form.Item>

        <Form.Item name="project_number" label="Dự án" rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}>
          <InputNumber className='w-full' placeholder="Ex: 1265" />
        </Form.Item>

        <Form.Item name="scale_number" label="Quy mô" rules={[{ required: true, message: 'Vui lòng không được bỏ trống' }]}>
          <InputNumber className='w-full' placeholder="Ex: 1000" />
        </Form.Item>
        <Form.Item>
          {isEdit ? (
            <div className='flex items-center'>
              <div
                className='mr-2 py-1 px-3 h-full bg-red-600 cursor-pointer text-white rounded'
                onClick={() => {
                  if (!loading) {
                    dispatch(setIsEdit(false))
                  }
                }}
              >
                Cancel
              </div>
              <Button type="primary" htmlType="submit" loading={loading}>
                Update
              </Button>
            </div>
          ) : (
            <Button type="primary" htmlType="submit" loading={loading}>
              Thêm
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default memo(CreateHomeOperationalResults);
