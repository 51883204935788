import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import HomeDesignProcessContainer from 'modules/home/design-process'

type Props = {
  //
};

const Home: React.FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Quy trình thiết kế thi công</title>
      </Helmet>
      <HomeDesignProcessContainer />
    </>
  );
};

export default memo(Home);
