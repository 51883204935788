export const SET_LIST_ABOUT_VALUE = 'SET_LIST_ABOUT_VALUE';
export const SET_IS_EDIT_ABOUT_VALUE = 'SET_IS_EDIT_ABOUT_VALUE';
export const SET_ABOUT_VALUE_EDIT = 'SET_ABOUT_VALUE_EDIT';
export const SET_SKIP_ABOUT_VALUE = 'SET_SKIP_ABOUT_VALUE';

export interface AboutValueState {
  aboutValueList: any;
  isEdit: boolean;
  aboutValueEdit: any;
  skip: number;
}

interface setListAboutValueAction {
  type: typeof SET_LIST_ABOUT_VALUE;
  payload: any;
}

interface setIsEditAction {
  type: typeof SET_IS_EDIT_ABOUT_VALUE;
  payload: any;
}

interface setAboutValueEditAction {
  type: typeof SET_ABOUT_VALUE_EDIT;
  payload: any;
}

interface setSkipAction {
  type: typeof SET_SKIP_ABOUT_VALUE;
  payload: number;
}

export type AboutValueAction = setListAboutValueAction | setIsEditAction | setAboutValueEditAction | setSkipAction;
