export const SET_LIST_HOME_OPERATIONAL_RESULTS = 'SET_LIST_HOME_OPERATIONAL_RESULTS';
export const SET_IS_EDIT_HOME_OPERATIONAL_RESULTS = 'SET_IS_EDIT_HOME_OPERATIONAL_RESULTS';
export const SET_HOME_OPERATIONAL_RESULTS_EDIT = 'SET_HOME_OPERATIONAL_RESULTS_EDIT';
export const SET_SKIP_HOME_OPERATIONAL_RESULTS = 'SET_SKIP_HOME_OPERATIONAL_RESULTS';

export interface HomeOperationalResultsState {
  homeOperationalResultsList: any;
  isEdit: boolean;
  homeOperationalResultsEdit: any;
  skip: number;
}

interface setListHomeOperationalResultsAction {
  type: typeof SET_LIST_HOME_OPERATIONAL_RESULTS;
  payload: any;
}

interface setIsEditAction {
  type: typeof SET_IS_EDIT_HOME_OPERATIONAL_RESULTS;
  payload: any;
}

interface setHomeOperationalResultsEditAction {
  type: typeof SET_HOME_OPERATIONAL_RESULTS_EDIT;
  payload: any;
}

interface setSkipAction {
  type: typeof SET_SKIP_HOME_OPERATIONAL_RESULTS;
  payload: number;
}

export type HomeOperationalResultsAction = setListHomeOperationalResultsAction | setIsEditAction | setHomeOperationalResultsEditAction | setSkipAction;
