import { SET_HOME_DESIGN_PROCESS_EDIT, SET_IS_EDIT_HOME_DESIGN_PROCESS, SET_LIST_HOME_DESIGN_PROCESS, SET_SKIP_HOME_DESIGN_PROCESS } from '../types/homeDesignProcessTypes';

export const setListHomeDesignProcess = (homeDesignProcesss: any) => {
  return {
    type: SET_LIST_HOME_DESIGN_PROCESS,
    payload: homeDesignProcesss,
  };
};


export const setIsEdit = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_HOME_DESIGN_PROCESS,
    payload: isEdit,
  };
};


export const setHomeDesignProcessEdit = (homeDesignProcess: any) => {
  return {
    type: SET_HOME_DESIGN_PROCESS_EDIT,
    payload: homeDesignProcess,
  };
};

export const setSkip = (skip: number) => {
  return {
    type: SET_SKIP_HOME_DESIGN_PROCESS,
    payload: skip,
  };
};