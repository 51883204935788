import { Card, Col, Row } from 'antd';
import Title from 'components/Title';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';

type Props = {
  // 
};

const DetailComponent: React.FC<Props> = () => {

  const { homeOperationalResultsList } = useSelector((state: RootState) => state.homeOperationalResults)

  return (
    <>
      <Row gutter={24}>
        <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} title="Mô tả" className='overflow-auto'>
            <div dangerouslySetInnerHTML={{ __html: homeOperationalResultsList?.description ?? '' }}></div>
          </Card>
        </Col>
        <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} title="Kinh Nghiệm" className='overflow-auto'>
            <Title level={4}>+<span className="text-red-500">{homeOperationalResultsList?.experience_number}</span> Năm</Title>
            <p>Kinh nghiệm hơn <span className="text-red-500">{homeOperationalResultsList?.experience_number}</span> năm hoạt động</p>
          </Card>
        </Col>

        <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} title="Dự Án" className='overflow-auto'>
            <Title level={4}>+<span className="text-red-500">{homeOperationalResultsList?.project_number}</span></Title>
            <p>Hơn <span className="text-red-500">{homeOperationalResultsList?.project_number}</span> dự án thiết kế, thi công nội thất</p>
          </Card>
        </Col>

        <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} title="Quy mô" className='overflow-auto'>
            <Title level={4}>+<span className="text-red-500">{homeOperationalResultsList?.experience_number}</span> m2</Title>
            <p>Nhà máy sản xuất quy mô hơn <span className="text-red-500">{homeOperationalResultsList?.scale_number}</span> m2</p>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default memo(DetailComponent);
