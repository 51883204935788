import { Button, Card, Col, Row } from 'antd';
import Title from 'components/Title';
import React, { memo, useEffect } from 'react';
import Create from './components/create';
import Detail from './components/detail';
import { useHomeOperationalResults } from 'hooks/useHomeOperationalResults';
import { EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { setHomeOperationalResultsEdit, setIsEdit } from 'store/actions/homeOperationalResultsAction';

type Props = {
  //
};

const HomeContainer: React.FC<Props> = () => {

  const dispatch = useDispatch()
  const { getDataList } = useHomeOperationalResults()
  const { skip, isEdit } = useSelector((state: RootState) => state?.homeOperationalResults)
  const { homeOperationalResultsList } = useSelector((state: RootState) => state.homeOperationalResults)

  useEffect(() => {
    getDataList(skip)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <div className="flex justify-between items-center mb-3">
            <Title level={4}>Thành quả hoạt động</Title>

            <Button
              icon={<EditOutlined />}
              size={'large'}
              style={{
                background: '#ffc107',
                border: '1px solid #ffc107',
                marginRight: 10
              }}
              onClick={() => {
                dispatch(setIsEdit(true))
                dispatch(setHomeOperationalResultsEdit(homeOperationalResultsList))
              }}
            >
              Chỉnh sửa
            </Button>
          </div>
        </Col>

        <Col sm={!homeOperationalResultsList || isEdit ? 12 : 24} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} className='overflow-auto'>
            <Detail />
          </Card>
        </Col>
        {
          (isEdit || !homeOperationalResultsList) && (
            <Col sm={12} xs={24} style={{ marginBottom: 20 }}>
              <Card bordered={true} className='overflow-auto'>
                <Create />
              </Card>
            </Col>
          )
        }
      </Row>
    </>
  );
};

export default memo(HomeContainer);
