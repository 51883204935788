import React from "react";
import { Layout } from 'antd';
import { Route, Switch, Redirect } from "react-router-dom";
import styles from './styles.module.css'
import Sider from 'components/Sider';
import { useSelector } from "react-redux";
import Header from 'components/Header';
// import NotFound from "components/NotFound"
import HomeIntro from 'views/home/intro';
import HomeOperationalResults from 'views/home/operational-results';
import HomeDesignProcess from 'views/home/design-process';
import HomeIntroFactory from 'views/home/intro-factory';
import HomeTrendDesign from 'views/home/trend-design';

import AboutService from 'views/about/service';
import AboutValue from 'views/about/value';
import AboutVision from 'views/about/vision';
import AboutTeam from 'views/about/team';

import ProjectType from 'views/project/type'
import CreateProject from 'views/project/create'
import ProjectDetail from 'views/project/view'
import ListProject from 'views/project/list'
import EditProject from 'views/project/update'

import BlogType from 'views/blog/type'
import CreateBlog from 'views/blog/create'
import BlogDetail from 'views/blog/view'
import ListBlog from 'views/blog/list'
import EditBlog from 'views/blog/update'

import { AppRoute } from "configs/router/config.router";
import PrivateRoute from "./private-route";
import { RootState } from "store/types";
import RouteLogin from "./route-login";
import Login from "views/login";
import Banner from "views/banner";
import Setting from "views/setting";
// import dashboard from "views/dashboard";
import Quote from "views/quote";

import CreateAdvise from 'views/advise/create'
import AdviseDetail from 'views/advise/view'
import ListAdvise from 'views/advise/list'
import EditAdvise from 'views/advise/update'
import AdviseType from 'views/advise/type'

const { Content } = Layout;

const Routers = () => {

  const { sidebarCollapse } = useSelector((state: RootState) => state?.global)

  return (
    <>
      <Switch>
        <RouteLogin path={AppRoute.login}>
          <Login />
        </RouteLogin>
        <Route>
          <div className={styles.wrapper}>
            <Layout>
              <Sider />
              <Layout className="site-layout">
                <Header />

                <Content className={`${styles.content} ${sidebarCollapse ? styles.show : ''}`}>
                  <Switch>
                    {/* <PrivateRoute exact exact path={AppRoute.dashboard} component={dashboard} />*/}
                    <PrivateRoute exact path={AppRoute.homeIntro}>
                      <HomeIntro />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.homeOperationalResults}>
                      <HomeOperationalResults />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.homeDesignProcess}>
                      <HomeDesignProcess />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.homeIntroFactory}>
                      <HomeIntroFactory />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.homeTrendDesign}>
                      <HomeTrendDesign />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.aboutService}>
                      <AboutService />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.aboutValue}>
                      <AboutValue />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.aboutVision}>
                      <AboutVision />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.aboutTeam}>
                      <AboutTeam />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.banner}>
                      <Banner />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.setting}>
                      <Setting />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.projectType}>
                      <ProjectType />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.projectDetail}>
                      <ProjectDetail />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.projectCreate}>
                      <CreateProject />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.project}>
                      <ListProject />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.projectUpdate}>
                      <EditProject />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.blogType}>
                      <BlogType />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.blogDetail}>
                      <BlogDetail />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.blogCreate}>
                      <CreateBlog />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.blog}>
                      <ListBlog />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.blogUpdate}>
                      <EditBlog />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.quote}>
                      <Quote />
                    </PrivateRoute>

                    <PrivateRoute exact path={AppRoute.adviseType}>
                      <AdviseType />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.adviseDetail}>
                      <AdviseDetail />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.adviseCreate}>
                      <CreateAdvise />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.advise}>
                      <ListAdvise />
                    </PrivateRoute>
                    <PrivateRoute exact path={AppRoute.adviseUpdate}>
                      <EditAdvise />
                    </PrivateRoute>

                    <PrivateRoute path={AppRoute.basic}>
                      <Redirect to={AppRoute.homeIntro} />
                    </PrivateRoute>
                    <Redirect path="*" to={AppRoute.login} />
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </div>
        </Route>
      </Switch>
    </>
  );
};

export default Routers;
