import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
	headers: {
		Accept: 'application/json'
	}
});

instance.interceptors.request.use(
	async (config: AxiosRequestConfig) => {
		// console.log(`%c ${config.method.toUpperCase()} - ${config.url}:`, 'color: #0086b3; font-weight: bold', config);

		return config;
	},
	(error) => {
		// console.log(`%c ${error.response.status}  :`, 'color: red; font-weight: bold', error.response.data);
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response: AxiosResponse) => {
		// console.log(` %c ${response.status} - ${getUrl(response.config)}:`, 'color: #008000; font-weight: bold', response);
		return response;
	},
	function (error) {
		if (error.response) {
			// console.log(`%c ${error.response.status}  :`, 'color: red; font-weight: bold', error.response);
			return Promise.reject({
				status: error.response.status,
				code: error.response.data.errorCode,
				message: error.response.data.errorMessage
			});
		} else if (error.request) {
			// console.log(`%c ${JSON.stringify(error)}  :`, 'color: red; font-weight: bold', error.response);
			return Promise.reject(error.request);
		} else {
			// console.log(`%c ${JSON.stringify(error)}  :`, 'color: red; font-weight: bold', 'có gì đó sai sai, hình như là setting sai');
			return Promise.reject(error);
		}
	}
);
