import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const aboutValueApi = {

	async create(data) {
		return instance.post(`about-value`, data, await headersAuthToken());
	},

	async update(data) {
		return instance.put(`about-value`, data, await headersAuthToken());
	},

	async delete(id) {
		return instance.delete(`about-value?id=${id}`, await headersAuthToken());
	},

	async getList(page) {
		return instance.get(`about-value/admin?page=${page}`, await headersAuthToken());
	},

};

