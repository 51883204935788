export const SET_LIST_HOME_TREND_DESIGN = 'SET_LIST_HOME_TREND_DESIGN';
export const SET_IS_EDIT_HOME_TREND_DESIGN = 'SET_IS_EDIT_HOME_TREND_DESIGN';
export const SET_HOME_TREND_DESIGN_EDIT = 'SET_HOME_TREND_DESIGN_EDIT';
export const SET_SKIP_HOME_TREND_DESIGN = 'SET_SKIP_HOME_TREND_DESIGN';

export interface HomeTrendDesignState {
  homeTrendDesignList: any;
  isEdit: boolean;
  homeTrendDesignEdit: any;
  skip: number;
}

interface setListHomeTrendDesignAction {
  type: typeof SET_LIST_HOME_TREND_DESIGN;
  payload: any;
}

interface setIsEditAction {
  type: typeof SET_IS_EDIT_HOME_TREND_DESIGN;
  payload: any;
}

interface setHomeTrendDesignEditAction {
  type: typeof SET_HOME_TREND_DESIGN_EDIT;
  payload: any;
}

interface setSkipAction {
  type: typeof SET_SKIP_HOME_TREND_DESIGN;
  payload: number;
}

export type HomeTrendDesignAction = setListHomeTrendDesignAction | setIsEditAction | setHomeTrendDesignEditAction | setSkipAction;
