import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const authApi = {

	login(data) {
		return instance.post(`admin/login`, data);
	},

	async validateToken() {
		return instance.get(`admin/token/validate`, await headersAuthToken());
	},

	async logout() {
		return instance.get(`admin/logout`, await headersAuthToken());
	},

};

