// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_main__-aGNK {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.styles_content__9Y03A {
  transition: all 0.2s linear;
  margin: 24px 16px;
  padding: 24px;
  min-height: calc(100vh - 112px);
  margin-left: 220px;
}

.styles_content__9Y03A.styles_show__v9T2b {
  margin-left: 100px;
}

@media (max-width: 639px) {
  .styles_content__9Y03A {
    padding-left: 0px;
    padding-right: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/routers/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;EACjB,aAAa;EACb,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;AACF","sourcesContent":[".main {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  z-index: 2;\n}\n\n.content {\n  transition: all 0.2s linear;\n  margin: 24px 16px;\n  padding: 24px;\n  min-height: calc(100vh - 112px);\n  margin-left: 220px;\n}\n\n.content.show {\n  margin-left: 100px;\n}\n\n@media (max-width: 639px) {\n  .content {\n    padding-left: 0px;\n    padding-right: 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `styles_main__-aGNK`,
	"content": `styles_content__9Y03A`,
	"show": `styles_show__v9T2b`
};
export default ___CSS_LOADER_EXPORT___;
