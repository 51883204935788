import { HomeIntroFactoryAction, HomeIntroFactoryState, SET_HOME_INTRO_FACTORY_EDIT, SET_IS_EDIT_HOME_INTRO_FACTORY, SET_LIST_HOME_INTRO_FACTORY, SET_SKIP_HOME_INTRO_FACTORY } from '../types/homeIntroFactoryTypes';

const initialState: HomeIntroFactoryState = {
  homeIntroFactoryList: null,
  isEdit: false,
  homeIntroFactoryEdit: null,
  skip: 1
};

const HomeIntroFactoryReducer = (state = initialState, action: HomeIntroFactoryAction): HomeIntroFactoryState => {
  switch (action.type) {
    case SET_LIST_HOME_INTRO_FACTORY:
      return {
        ...state,
        homeIntroFactoryList: action?.payload,
      };
    case SET_IS_EDIT_HOME_INTRO_FACTORY:
      return {
        ...state,
        isEdit: action?.payload,
      };
    case SET_HOME_INTRO_FACTORY_EDIT:
      return {
        ...state,
        homeIntroFactoryEdit: action?.payload,
      };
    case SET_SKIP_HOME_INTRO_FACTORY:
      return {
        ...state,
        skip: action?.payload,
      };
    default:
      return state;
  }
};

export default HomeIntroFactoryReducer;
