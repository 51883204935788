import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import AboutValueContainer from 'modules/about/value'

type Props = {
  //
};

const Home: React.FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Thông tin thêm</title>
      </Helmet>
      <AboutValueContainer />
    </>
  );
};

export default memo(Home);
