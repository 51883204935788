import Detail from 'modules/blog/view';
import React, { memo } from 'react';

type Props = {
  //
};

const DetailBlog: React.FC<Props> = () => {
  return (
    <>
      <Detail />
    </>
  );
};

export default memo(DetailBlog);
