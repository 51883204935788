import { homeOperationalResultsApi } from "configs/api/homeOperationalResults"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setListHomeOperationalResults } from "store/actions/homeOperationalResultsAction"
import { removeNullUndefined } from "utils"

export function useHomeOperationalResults() {

  const disPatch = useDispatch()

  const createHomeOperationalResults = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = homeOperationalResultsApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const edithomeOperationalResults = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = homeOperationalResultsApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteHomeOperationalResults = async (id) => {

    try {
      const promise = homeOperationalResultsApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async (page) => {

    try {
      const promise = homeOperationalResultsApi.getList(page)
      return await promise.then(async (res) => {
        // disPatch(setListHomeOperationalResults(res.data))
        // Data only 1 item in array
        disPatch(setListHomeOperationalResults(res.data.data[0]))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    createHomeOperationalResults,
    edithomeOperationalResults,
    getDataList,
    deleteHomeOperationalResults,
  }
}