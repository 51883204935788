import { homeDesignProcessApi } from "configs/api/homeDesignProcess"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setListHomeDesignProcess } from "store/actions/homeDesignProcessAction"
import { removeNullUndefined } from "utils"

export function useHomeDesignProcess() {

  const disPatch = useDispatch()

  const createHomeDesignProcess = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = homeDesignProcessApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const edithomeDesignProcess = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = homeDesignProcessApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteHomeDesignProcess = async (id) => {

    try {
      const promise = homeDesignProcessApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async (page) => {

    try {
      const promise = homeDesignProcessApi.getList(page)
      return await promise.then(async (res) => {
        disPatch(setListHomeDesignProcess(res.data))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    createHomeDesignProcess,
    edithomeDesignProcess,
    getDataList,
    deleteHomeDesignProcess,
  }
}