import { Card, Col, Row } from 'antd';
import ImageAnt from 'components/ImageAnt';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';
import YouTube from 'react-youtube';
import getYouTubeID from 'get-youtube-id';

type Props = {
  // 
};

const DetailComponent: React.FC<Props> = () => {

  const { homeIntroFactoryList } = useSelector((state: RootState) => state.homeIntroFactory)

  return (
    <>
      <Row gutter={24}>
        <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} title="Ảnh nền" className='overflow-auto'>
            <ImageAnt id={homeIntroFactoryList?.background ?? ''} />
          </Card>
        </Col>

        <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
          <Card bordered={true} title="Video" className='overflow-auto'>
            {
              homeIntroFactoryList?.videoUrl && (
                <YouTube
                  videoId={getYouTubeID(homeIntroFactoryList?.videoUrl ?? '') ?? ''}
                  opts={{
                    height: '400px',
                    width: '100%',
                  }}
                />

              )
            }
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default memo(DetailComponent);
