import { SET_HOME_TREND_DESIGN_EDIT, SET_IS_EDIT_HOME_TREND_DESIGN, SET_LIST_HOME_TREND_DESIGN, SET_SKIP_HOME_TREND_DESIGN } from '../types/homeTrendDesignTypes';

export const setListHomeTrendDesign = (homeTrendDesigns: any) => {
  return {
    type: SET_LIST_HOME_TREND_DESIGN,
    payload: homeTrendDesigns,
  };
};


export const setIsEdit = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_HOME_TREND_DESIGN,
    payload: isEdit,
  };
};


export const setHomeTrendDesignEdit = (homeTrendDesign: any) => {
  return {
    type: SET_HOME_TREND_DESIGN_EDIT,
    payload: homeTrendDesign,
  };
};

export const setSkip = (skip: number) => {
  return {
    type: SET_SKIP_HOME_TREND_DESIGN,
    payload: skip,
  };
};