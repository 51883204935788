import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const quoteApi = {

	async detail(id) {
		return instance.get(`quote/${id}`, await headersAuthToken());
	},

	async seen(id) {
		return instance.put(`quote/seen/${id}`, {}, await headersAuthToken());
	},

	async delete(id) {
		return instance.delete(`quote/${id}`, await headersAuthToken());
	},

	async getList(page) {
		return instance.get(`quote?page=${page}`, await headersAuthToken());
	},

};

