import React, { memo } from 'react';
import ListAdviseContainer from 'modules/advise/list'

type Props = {
  //
};

const ListAdvise: React.FC<Props> = () => {
  return (
    <>
      <ListAdviseContainer />
    </>
  );
};

export default memo(ListAdvise);
