import { SET_ABOUT_TEAM_EDIT, SET_IS_EDIT_ABOUT_TEAM, SET_LIST_ABOUT_TEAM, SET_SKIP_ABOUT_TEAM } from '../types/aboutTeamTypes';

export const setListAboutTeam = (aboutTeams: any) => {
  return {
    type: SET_LIST_ABOUT_TEAM,
    payload: aboutTeams,
  };
};


export const setIsEdit = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_ABOUT_TEAM,
    payload: isEdit,
  };
};


export const setAboutTeamEdit = (aboutTeam: any) => {
  return {
    type: SET_ABOUT_TEAM_EDIT,
    payload: aboutTeam,
  };
};

export const setSkip = (skip: number) => {
  return {
    type: SET_SKIP_ABOUT_TEAM,
    payload: skip,
  };
};