import { aboutServiceApi } from "configs/api/aboutService"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setListAboutService } from "store/actions/aboutServiceAction"
import { removeNullUndefined } from "utils"

export function useAboutService() {

  const disPatch = useDispatch()

  const createAboutService = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = aboutServiceApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const editaboutService = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = aboutServiceApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteAboutService = async (id) => {

    try {
      const promise = aboutServiceApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async (page) => {

    try {
      const promise = aboutServiceApi.getList(page)
      return await promise.then(async (res) => {
        disPatch(setListAboutService(res.data))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    createAboutService,
    editaboutService,
    getDataList,
    deleteAboutService,
  }
}