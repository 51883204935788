import React, { memo } from 'react';
import TypeAdviseContainer from 'modules/advise/type'
type Props = {
  //
};

const TypeAdvise: React.FC<Props> = () => {
  return (
    <>
      <TypeAdviseContainer />
    </>
  );
};

export default memo(TypeAdvise);
