import React, { memo } from 'react';
import { DeleteOutlined, EyeOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Table, Tooltip } from 'antd';
import { RootState } from 'store/types';
import { useSelector } from 'react-redux';
import styles from './styles.module.css'
import { useQuote } from 'hooks/useQuote';
import { useHistory, useLocation } from 'react-router-dom';
import { AppRoute } from 'configs/router/config.router';

type Props = {
  setItemView: any,
  setIsView: any,
};

const { confirm } = Modal;

const ListType: React.FC<Props> = ({ setItemView, setIsView }) => {

  const history = useHistory()
  const { quoteList } = useSelector((state: RootState) => state.quote)
  const { deleteQuote, getDataList } = useQuote()

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("page");

  const showConfirmDelete = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <Button>Are you sure?</Button>,
      onOk() {
        deleteQuote(id).then((res) => {
          getDataList(param ? param : 1)
        });
      },
      onCancel() {
      },
    });
  };

  const columns = [
    {
      title: 'Tên khách hàng',
      dataIndex: 'id',
      width: '20%',
      render: (cell, row, index) => {
        return <p>{row.name}</p>;
      },
    },
    {
      title: 'SĐT',
      dataIndex: 'id',
      width: '20%',
      render: (cell, row, index) => {
        return <p>{row.phone}</p>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'id',
      width: '20%',
      render: (cell, row, index) => {
        return <p>{row.email}</p>;
      },
    },
    {
      title: 'Nội dung',
      dataIndex: 'id',
      width: '20%',
      render: (cell, row, index) => {
        return <div className={styles.content} dangerouslySetInnerHTML={{ __html: row.content }} />;
      },
    },
    {
      title: 'Hành động',
      dataIndex: 'id',
      width: '5%',
      render: (cell, row, index) => {
        return (
          <Space>
            <Tooltip title={'View'}>
              <div
                onClick={() => {
                  setItemView(row)
                  setIsView(true)
                }}
              >
                <Button icon={<EyeOutlined />} />
              </div>
            </Tooltip>
            <Tooltip title={'Delete'}>
              <Button
                onClick={() => {
                  showConfirmDelete(row._id)
                }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        // @ts-ignore
        dataSource={quoteList?.data || []}
        columns={columns}
        style={{ margin: 0 }}
        className="table_list"
        onRow={(record, index) => ({
          style: {
            background: record.seen ? 'white' : '#dae5f5',
          }
        })}
        pagination={{
          position: ['bottomCenter'],
          current: Number(param ? param : 1),
          total: quoteList?.totalItem ? quoteList?.totalItem : 1,
          pageSize: quoteList?.itemPerpage ? quoteList?.itemPerpage : 10,
          onChange: (skip: number) => {
            history.push({
              pathname: AppRoute.quote,
              search: `?page=${skip}`
            })
          },
          showSizeChanger: false,
        }}
      />
    </>
  );
};

export default memo(ListType);
