import { aboutVisionApi } from "configs/api/aboutVision"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setListAboutVision } from "store/actions/aboutVisionAction"
import { removeNullUndefined } from "utils"

export function useAboutVision() {

  const disPatch = useDispatch()

  const createAboutVision = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = aboutVisionApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const editaboutVision = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = aboutVisionApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteAboutVision = async (id) => {

    try {
      const promise = aboutVisionApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async (page) => {

    try {
      const promise = aboutVisionApi.getList(page)
      return await promise.then(async (res) => {
        disPatch(setListAboutVision(res.data.data[0]))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    createAboutVision,
    editaboutVision,
    getDataList,
    deleteAboutVision,
  }
}