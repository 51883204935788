import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const blogApi = {

	async create(data) {
		return instance.post(`blog`, data, await headersAuthToken());
	},

	async getDetail(id) {
		return instance.get(`blog/admin/detail/${id}`, await headersAuthToken());
	},

	async getList(page, type?: string) {
		return instance.get(`blog/admin/list?page=${page}${type ? `&type=${type}` : ''}`, await headersAuthToken());
	},

	async update(data) {
		return instance.put(`blog`, data, await headersAuthToken());
	},

	async delete(id) {
		return instance.delete(`blog/${id}`, await headersAuthToken());
	},

};

