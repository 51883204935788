import { HomeDesignProcessAction, HomeDesignProcessState, SET_HOME_DESIGN_PROCESS_EDIT, SET_IS_EDIT_HOME_DESIGN_PROCESS, SET_LIST_HOME_DESIGN_PROCESS, SET_SKIP_HOME_DESIGN_PROCESS } from '../types/homeDesignProcessTypes';

const initialState: HomeDesignProcessState = {
  homeDesignProcessList: null,
  isEdit: false,
  homeDesignProcessEdit: null,
  skip: 1
};

const HomeDesignProcessReducer = (state = initialState, action: HomeDesignProcessAction): HomeDesignProcessState => {
  switch (action.type) {
    case SET_LIST_HOME_DESIGN_PROCESS:
      return {
        ...state,
        homeDesignProcessList: action?.payload,
      };
    case SET_IS_EDIT_HOME_DESIGN_PROCESS:
      return {
        ...state,
        isEdit: action?.payload,
      };
    case SET_HOME_DESIGN_PROCESS_EDIT:
      return {
        ...state,
        homeDesignProcessEdit: action?.payload,
      };
    case SET_SKIP_HOME_DESIGN_PROCESS:
      return {
        ...state,
        skip: action?.payload,
      };
    default:
      return state;
  }
};

export default HomeDesignProcessReducer;
