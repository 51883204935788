import { aboutValueApi } from "configs/api/aboutValue"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setListAboutValue } from "store/actions/aboutValueAction"
import { removeNullUndefined } from "utils"

export function useAboutValue() {

  const disPatch = useDispatch()

  const createAboutValue = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = aboutValueApi.create(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const editaboutValue = async (_values) => {

    try {
      const values = removeNullUndefined(_values)

      const promise = aboutValueApi.update(values)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const deleteAboutValue = async (id) => {

    try {
      const promise = aboutValueApi.delete(id)
      const loading = toast.loading("Pending...")

      return await promise.then(async (res) => {
        toast.update(loading, { render: "Success", type: "success", autoClose: 5000, isLoading: false });
        return {
          success: true
        }
      }).catch(async (err) => {
        toast.update(loading, { render: "Error", type: "error", autoClose: 5000, isLoading: false });
        throw err
      })
    } catch (err) {
      // 
    }

  }

  const getDataList = async (page) => {

    try {
      const promise = aboutValueApi.getList(page)
      return await promise.then(async (res) => {
        disPatch(setListAboutValue(res.data))
        return true
      }).catch(async (err) => {
        throw err
      })
    } catch (err) {
      // 
    }

  }

  return {
    createAboutValue,
    editaboutValue,
    getDataList,
    deleteAboutValue,
  }
}