import { SET_BANNER_EDIT, SET_IS_EDIT_BANNER, SET_LIST_BANNER, SET_SKIP_BANNER } from '../types/bannerTypes';

export const setListBanner = (banners: any) => {
  return {
    type: SET_LIST_BANNER,
    payload: banners,
  };
};


export const setIsEdit = (isEdit: boolean) => {
  return {
    type: SET_IS_EDIT_BANNER,
    payload: isEdit,
  };
};


export const setBannerEdit = (banner: any) => {
  return {
    type: SET_BANNER_EDIT,
    payload: banner,
  };
};

export const setSkip = (skip: number) => {
  return {
    type: SET_SKIP_BANNER,
    payload: skip,
  };
};