import Update from 'modules/blog/update';
import React, { memo } from 'react';

type Props = {
  //
};

const UpdateBlog: React.FC<Props> = () => {
  return (
    <>
      <Update />
    </>
  );
};

export default memo(UpdateBlog);
