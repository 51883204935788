import React, { memo } from 'react';
import Title from 'components/Title';
import { Col, Row } from 'antd';
import Form from '../components/form';

type Props = {
  //
};

const CreateAdviseContainer: React.FC<Props> = () => {
  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <Title level={4}>Thêm loại tư vấn</Title>
        </Col>

        <Col sm={24} xs={24} style={{ marginBottom: 20 }}>
          <Form isEdit={false} />
        </Col>
      </Row>
    </>
  );
};

export default memo(CreateAdviseContainer);
