import { headersAuthToken } from '../../utils/auth';
import { instance } from './../../apiBase/instance';

export const projectApi = {

	async create(data) {
		return instance.post(`project`, data, await headersAuthToken());
	},

	async getDetail(id) {
		return instance.get(`project/admin/detail/${id}`, await headersAuthToken());
	},

	async getList(page, type?: string) {
		return instance.get(`project/admin/list?page=${page}${type ? `&type=${type}` : ''}`, await headersAuthToken());
	},

	async update(data) {
		return instance.put(`project`, data, await headersAuthToken());
	},

	async delete(id) {
		return instance.delete(`project/${id}`, await headersAuthToken());
	},

};

