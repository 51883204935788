export const SET_LIST_BANNER = 'SET_LIST_BANNER';
export const SET_IS_EDIT_BANNER = 'SET_IS_EDIT_BANNER';
export const SET_BANNER_EDIT = 'SET_BANNER_EDIT';
export const SET_SKIP_BANNER = 'SET_SKIP_BANNER';

export interface BannerState {
  bannerList: any;
  isEdit: boolean;
  bannerEdit: any;
  skip: number;
}

interface setListBannerAction {
  type: typeof SET_LIST_BANNER;
  payload: any;
}

interface setIsEditAction {
  type: typeof SET_IS_EDIT_BANNER;
  payload: any;
}

interface setBannerEditAction {
  type: typeof SET_BANNER_EDIT;
  payload: any;
}

interface setSkipAction {
  type: typeof SET_SKIP_BANNER;
  payload: number;
}

export type BannerAction = setListBannerAction | setIsEditAction | setBannerEditAction | setSkipAction;
