import React, { memo, useRef, useState } from "react";
import { Layout } from 'antd';
import styles from './styles.module.css'
import { useRefCustom } from "hooks/useRefCustom";
import { LogoutOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import { useAuthentication } from "hooks/useAuthentication";
import { useHistory } from "react-router-dom";
import { AppRoute } from "configs/router/config.router";

const { Header } = Layout;

const HeaderCpn = () => {

    const { sidebarCollapse } = useSelector((state: RootState) => state?.global)
    const [show, setShow] = useState(false);
    const { logout } = useAuthentication()
    const menuRef = useRef(null);
    const history = useHistory()
    useRefCustom(menuRef, setShow)

    const onHandleLogout = async () => {
        await logout().then((res) => {
            history.push(AppRoute.login)
        })
    }

    return (
        <>
            <div className={`${styles.wrapper}`}>
                <Header className={`${styles.header} ${sidebarCollapse ? styles.active : ''}`}>
                    <div className={styles.dropdown} ref={menuRef}>
                        <img className={styles.avatar} onClick={() => setShow(!show)} src={require(`assets/images/svg/user-circle.svg`).default} alt="avatar" />
                        <div className={`${styles.menu} ${show ? styles.active : ''}`}>
                            <div className={styles.info}>
                                <div className={`${styles.name} text-center`}>Admin</div>
                                <div className={styles.main}>admin@sdecor.com</div>
                            </div>
                            <div className={styles.rowMenu} onClick={onHandleLogout}>
                                <div className={styles.item}>
                                    <LogoutOutlined />
                                    Logout
                                </div>
                            </div>
                        </div>
                    </div>
                </Header>
            </div>
        </>
    )
}

export default memo(HeaderCpn)