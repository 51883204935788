export const SET_LIST_HOME_INTRO_FACTORY = 'SET_LIST_HOME_INTRO_FACTORY';
export const SET_IS_EDIT_HOME_INTRO_FACTORY = 'SET_IS_EDIT_HOME_INTRO_FACTORY';
export const SET_HOME_INTRO_FACTORY_EDIT = 'SET_HOME_INTRO_FACTORY_EDIT';
export const SET_SKIP_HOME_INTRO_FACTORY = 'SET_SKIP_HOME_INTRO_FACTORY';

export interface HomeIntroFactoryState {
  homeIntroFactoryList: any;
  isEdit: boolean;
  homeIntroFactoryEdit: any;
  skip: number;
}

interface setListHomeIntroFactoryAction {
  type: typeof SET_LIST_HOME_INTRO_FACTORY;
  payload: any;
}

interface setIsEditAction {
  type: typeof SET_IS_EDIT_HOME_INTRO_FACTORY;
  payload: any;
}

interface setHomeIntroFactoryEditAction {
  type: typeof SET_HOME_INTRO_FACTORY_EDIT;
  payload: any;
}

interface setSkipAction {
  type: typeof SET_SKIP_HOME_INTRO_FACTORY;
  payload: number;
}

export type HomeIntroFactoryAction = setListHomeIntroFactoryAction | setIsEditAction | setHomeIntroFactoryEditAction | setSkipAction;
