export const SET_LIST_ABOUT_TEAM = 'SET_LIST_ABOUT_TEAM';
export const SET_IS_EDIT_ABOUT_TEAM = 'SET_IS_EDIT_ABOUT_TEAM';
export const SET_ABOUT_TEAM_EDIT = 'SET_ABOUT_TEAM_EDIT';
export const SET_SKIP_ABOUT_TEAM = 'SET_SKIP_ABOUT_TEAM';

export interface AboutTeamState {
  aboutTeamList: any;
  isEdit: boolean;
  aboutTeamEdit: any;
  skip: number;
}

interface setListAboutTeamAction {
  type: typeof SET_LIST_ABOUT_TEAM;
  payload: any;
}

interface setIsEditAction {
  type: typeof SET_IS_EDIT_ABOUT_TEAM;
  payload: any;
}

interface setAboutTeamEditAction {
  type: typeof SET_ABOUT_TEAM_EDIT;
  payload: any;
}

interface setSkipAction {
  type: typeof SET_SKIP_ABOUT_TEAM;
  payload: number;
}

export type AboutTeamAction = setListAboutTeamAction | setIsEditAction | setAboutTeamEditAction | setSkipAction;
