import { SET_LIST_QUOTE, SET_SKIP_QUOTE } from '../types/quoteTypes';

export const setListQuote = (quotes: any) => {
  return {
    type: SET_LIST_QUOTE,
    payload: quotes,
  };
};

export const setSkip = (skip: number) => {
  return {
    type: SET_SKIP_QUOTE,
    payload: skip,
  };
};